import { useSelector } from "react-redux";
import { RootState } from "../store";


const useWorkspaceAccess = (uniCode: string) => {
  const workspaces = useSelector((state: RootState) => state.workspaceState.workspaces);
  return workspaces.some((workspace) => workspace.uniCode === uniCode);
};

export default useWorkspaceAccess;
