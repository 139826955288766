import React, { useEffect, useState } from 'react'
// import QRCode from '../../assets/QRCode.png'
import QRCode from 'qrcode.react';
import './../../css/Invoice.css'
import Trash from './../../assets/Trash.svg'
import axios from 'axios'
import Alert from './../shapes/Alert'
import InvoiceView from './InvoiceView'
import { url } from 'inspector'
import { addCommaAndSeparator, getCurrentDate, removeDecimalPoint, removeSeparator } from '../../utilities/helper'
import InvoiceIssued from './InvoiceIssued'
import '../../css/InvoiceIssued.css'
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { RootState } from '../../store';
import { WorkspaceAddress } from '../../types/WorkspaceAddress'
import { BankInfo } from '../../types/BankInfo'
import { Workspace } from '../../types/Workspace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


interface Client {
  id: number;
  name: string;
  email: string;
  is_person: string;
  currency: string;
}

interface Props {
  qrCodeDisplay: boolean;
}
const Invoice: React.FC<Props> = ({ qrCodeDisplay }) => {
  const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
  const [searchParams] = useSearchParams();
  const invoiceQReferenceNumber = searchParams.get('id');

  const { uniCodeParam, invoiceType, } = useParams<{ uniCodeParam: string, invoiceType: string }>();
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [isloading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [client, setClient] = useState('');
  const [invoiceUniqueCode, setInvoiceUniqueCode] = useState('');

  const [invoiceNumber, setInvoiceNumber] = useState('INV-######');
  const [invoiceUniCode, setInvoiceUniCode] = useState('INV-######');
  const [invoiceUrl, setInvoiceUrl] = useState('');

  const [formVisible, setFormVisible] = useState(true);
  const [issueDate, setIssueDate] = useState('');
  const [totalInvoice, setTotalInvoice] = useState('');
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [legalName, setLegalName] = useState('');
  const [discount, setDiscount] = useState(0);
  const [fetched, setFetched] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  // const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>(
  //   {
  //     id: -1,
  //     name: '',
  //     snippet: '',
  //     description: '',
  //     uniCode: '',
  //     image: '',
  //   }
  // );
  const [bankInfo, setBankInfo] = useState<BankInfo>({
    bankName: '',
    accountName: '',
    accountNumber: '',
    iban: '',
    swiftCode: '',
    bankAddress: '',
    trnNumber: '',
    crNumber: '',
  });
  const [workspaceAddress, setWorkspaceAddress] = useState<WorkspaceAddress>({
    country: '',
    city: '',
    district: '',
    streetName: '',
    postalCode: '',
  });

  const user = useSelector((state: RootState) => state.userState);

  const fetchSuccessInvoice = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-success-invoice/${uniCodeParam}`;
    try {
      setIsLoading(true)
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = response.data;
      const bankInfo = response.data.bank_info;
      const workspaceAddress = response.data.workspace_address;
      // setSelectedWorkspace(response.data.workspace);
      setBankInfo(bankInfo);
      setWorkspaceAddress(workspaceAddress);
      console.log("DATA");
      console.log(data);

      const invoiceResponse = response.data.invoice;
      const popupTitle = document.getElementById('popup-title') as HTMLElement;

      if (invoiceResponse.sale_type === 'CREDIT NOTE') {
        popupTitle.innerHTML = 'CREDIT NOTE';
      } else if (invoiceResponse.sale_type === 'DEBIT NOTE') {
        popupTitle.innerHTML = 'DEBIT NOTE';
      } else if (invoiceResponse.sale_type === 'PROFORMA INVOICE') {
        popupTitle.innerHTML = 'PROFORMA INVOICE';
      } else {
        popupTitle.innerHTML = 'VAT INVOICE';
      }



      document.getElementById('invoice-number')!.textContent = invoiceResponse.invoice_number;
      document.getElementById('issue-date')!.textContent = invoiceResponse.issue_date;
      document.getElementById('client-name')!.textContent = invoiceResponse.client_name;
      document.getElementById('total_after_vat')!.textContent = `SAR ${addCommaAndSeparator(response.data.total_after_vat)}`;

      // invoice details
      const invoiceDetailsResponse = response.data.invoice_details
      // console.clear();
      // console.log('invoiceDetailsResponse');
      // console.log(invoiceDetailsResponse);
      const invoiceView = document.querySelector('.invoice-popup-right') as HTMLElement;
      const customerName = invoiceView.querySelector('#popup-client-name') as HTMLElement;
      const popupNote = invoiceView.querySelector('#popup-note') as HTMLElement;
      const popupInvoiceNumber = invoiceView.querySelector('#popup-invoice-number') as HTMLElement;
      const popupIssueDate = invoiceView.querySelector('#popup-issue-date') as HTMLElement;
      const popupDueDate = invoiceView.querySelector('#popup-due-date') as HTMLElement;
      const popupTable = invoiceView.querySelector('table') as HTMLTableElement;
      const popupTbody = popupTable.getElementsByTagName('tbody')[0];
      const popupRows = popupTbody.getElementsByTagName('tr');


      customerName.innerHTML = invoiceResponse.client_name;
      popupNote.innerHTML = invoiceResponse.note;
      popupInvoiceNumber.innerHTML = invoiceResponse.invoice_number;
      popupIssueDate.innerHTML = invoiceResponse.issue_date;
      popupDueDate.innerHTML = invoiceResponse.due_date;

      if (popupRows.length === 1) {
        invoiceDetailsResponse.forEach((invoiceDetail, index) => {
          const popupRow = popupTbody.getElementsByTagName('tr')[0] as HTMLTableRowElement;

          if (index > 0) {
            const newRow = popupRow.cloneNode(true) as HTMLTableRowElement;
            popupTbody.appendChild(newRow);
            console.log(popupTbody);

          }
          const description = popupRow.querySelector('input[name="item_description[]"]') as HTMLInputElement;
          const quantity = popupRow.querySelector('input[name="quantity[]"]') as HTMLInputElement;
          const unitCost = popupRow.querySelector('input[name="unit_cost[]"]') as HTMLInputElement;
          const taxable_amount = popupRow.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
          const tax_rate = popupRow.querySelector('input[name="tax_rate[]"]') as HTMLInputElement;
          const tax = popupRow.querySelector('input[name="tax[]"]') as HTMLInputElement;
          const amount = popupRow.querySelector('input[name="amount[]"]') as HTMLInputElement;

          const descriptionPTag = popupRow.querySelector('p[data-name="item_description[]"]') as HTMLInputElement;
          const quantityPTag = popupRow.querySelector('p[data-name="quantity[]"]') as HTMLInputElement;
          const unitCostPTag = popupRow.querySelector('p[data-name="unit_cost[]"]') as HTMLInputElement;
          const taxable_amountPTag = popupRow.querySelector('p[data-name="taxable_amount[]"]') as HTMLInputElement;
          const tax_ratePTag = popupRow.querySelector('p[data-name="tax_rate[]"]') as HTMLInputElement;
          const taxPTag = popupRow.querySelector('p[data-name="tax[]"]') as HTMLInputElement;
          const amountPTag = popupRow.querySelector('p[data-name="amount[]"]') as HTMLInputElement;

          const productNamePTag = popupRow.querySelector('p[data-name="product_name[]"]') as HTMLInputElement;
          const productCodePTag = popupRow.querySelector('p[data-name="product_code[]"]') as HTMLInputElement;
          const unitTypePTag = popupRow.querySelector('p[data-name="unit_type[]"]') as HTMLInputElement;

          if (description) description!.value = invoiceDetail.item_description;
          if (quantity) quantity!.value = invoiceDetail.quantity;
          if (unitCost) unitCost!.value = addCommaAndSeparator(invoiceDetail.unit_cost);
          if (taxable_amount) taxable_amount!.value = addCommaAndSeparator(invoiceDetail.taxable_amount);
          if (tax_rate) tax_rate!.value = invoiceDetail.tax_rate ? invoiceDetail.tax_rate + "%" : '0%';

          if (description) descriptionPTag!.innerHTML = invoiceDetail.item_description;
          if (quantity) quantityPTag!.innerHTML = invoiceDetail.quantity;
          if (unitCost) unitCostPTag!.innerHTML = addCommaAndSeparator(invoiceDetail.unit_cost);
          if (taxable_amount) taxable_amountPTag!.innerHTML = addCommaAndSeparator(invoiceDetail.taxable_amount);
          if (tax_rate) tax_ratePTag!.innerHTML = invoiceDetail.tax_rate ? invoiceDetail.tax_rate + "%" : '0%';

          if (productNamePTag) productNamePTag!.innerHTML = invoiceDetail.product_name ? invoiceDetail.product_name : '';
          if (productCodePTag) productCodePTag!.innerHTML = invoiceDetail.product_code ? invoiceDetail.product_code : '';
          if (unitTypePTag) unitTypePTag!.innerHTML = invoiceDetail.unit_type ? invoiceDetail.unit_type : '';

          const taxRate = popupRow.querySelector('input[name="tax_rate[]"]') as HTMLSelectElement;
          const taxableAmount = popupRow.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
          const taxRateValue = taxRate?.value || '0';
          const taxableAmountValue = taxableAmount?.value || '0';
          let taxValue = (parseFloat(removeSeparator(taxableAmountValue)) / 100) * parseFloat(removeSeparator(taxRateValue))
          tax!.value = addCommaAndSeparator(removeSeparator(taxValue.toString()));
          amount!.value = addCommaAndSeparator((parseFloat(removeSeparator(taxable_amount.value)) + taxValue));

          taxPTag!.innerHTML = addCommaAndSeparator(removeSeparator(taxValue.toString()));
          amountPTag!.innerHTML = addCommaAndSeparator((parseFloat(removeSeparator(taxable_amount.value)) + taxValue));

          const totalTaxableAmount = document.querySelector('.tb-vat') as HTMLElement;
          const totalVAT = document.querySelector('.t-vat') as HTMLElement;
          const totalAfterVAT = document.querySelector('.total-after-vat') as HTMLElement;

          totalTaxableAmount.innerHTML = addCommaAndSeparator(response.data.total_taxable_amount);
          totalVAT.innerHTML = addCommaAndSeparator(response.data.total_vat);
          totalAfterVAT.innerHTML = addCommaAndSeparator(response.data.total_after_vat);
        });
      }
      setInvoiceUniCode(response.data.invoice.uni_code);
      if (uniCodeParam) {
        setInvoiceUrl(process.env.REACT_APP_WEB_URL + uniCodeParam + '/invoice/proforma/' + response.data.invoice.uni_code);
      }
      setShowQRCode(true);
    } catch (error) {
      console.error('Error fetching clients', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const submitForm = document.getElementById('submit-form') as HTMLFormElement;
    submitForm.classList.add('inactive');

    if (qrCodeDisplay && uniCodeParam && !fetched) {
      fetchSuccessInvoice(); // Assuming this function is defined elsewhere
      setFetched(true);
    }
  }, [qrCodeDisplay, uniCodeParam]);


  const fetchInitialData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/invoice/${uniCodeParam}?invoiceQ_reference_number=${invoiceQReferenceNumber}`;
    try {
      setIsLoading(true)
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const invoiceNumberResponse = response.data.last_invoice_number;

      const bankInfo = response.data.bank_info;
      const workspaceAddress = response.data.workspace_address;
      // alert(response.data.invoice_unique_code)
      setInvoiceUniqueCode(response.data.invoice_unique_code)
      if (process.env.REACT_APP_WEB_URL) {
        setInvoiceUrl(process.env.REACT_APP_WEB_URL + uniCodeParam + '/invoice/proforma/' + response.data.invoice_unique_code);
      }
      setWorkspaceAddress(workspaceAddress);
      setLegalName(response.data.legal_name);
      // setSelectedWorkspace(response.data.selected_workspace);
      setBankInfo(bankInfo);

      const fixedInvoiceNumber = 6 - invoiceNumberResponse.toString().length;
      const invoiceNumber = 'INV-' + '0'.repeat(fixedInvoiceNumber) + invoiceNumberResponse;
      const saleType = document.getElementById('sale-type-select') as HTMLSelectElement;
      setClients(response.data.clients);

      const invoiceView = document.querySelector('.invoice-popup-right') as HTMLElement;
      const popupInvoiceNumber = invoiceView.querySelector('#popup-invoice-number') as HTMLElement;
      if (popupInvoiceNumber) {
        popupInvoiceNumber.innerHTML = invoiceNumber;
      }
      setInvoiceNumber(invoiceNumber);
      document.getElementById('sale-type-select')?.classList.remove('inactive')
    } catch (error) {
      console.error('Error fetching clients', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!qrCodeDisplay) {
      fetchInitialData();
    }

    document.getElementById('issue-date-input')?.setAttribute('value', getCurrentDate());
    const popupIssueDate = document.getElementById('popup-issue-date') as HTMLElement;
    if (popupIssueDate) {
      popupIssueDate.innerHTML = getCurrentDate();
      setIssueDate(getCurrentDate());
    }
  }, []);


  const handleAddRecord = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tbody = table.getElementsByTagName('tbody')[0];
    const row = tbody.getElementsByTagName('tr')[0];
    row.querySelector('.trash-cell')?.classList.remove('overnight');
    const newRow = row.cloneNode(true) as HTMLTableRowElement;
    const inputs = newRow.getElementsByTagName('input');
    const selects = newRow.getElementsByTagName('select');
    const trashCell = newRow.querySelector('.trash-cell') as HTMLElement;
    // const invoiceView = document.querySelector('invoice-popup-right') as HTMLElement;
    // select the div with class invoice-popup-right
    const invoiceView = document.querySelector('.invoice-popup-right') as HTMLElement;
    const popuptable = invoiceView.querySelector('table') as HTMLTableElement;
    const popupTbody = popuptable.getElementsByTagName('tbody')[0];
    const popupRow = popupTbody.getElementsByTagName('tr')[0];
    const newPopupRow = popupRow.cloneNode(true) as HTMLTableRowElement;
    const popupInputs = newPopupRow.getElementsByTagName('input');
    const popupPTags = newPopupRow.getElementsByTagName('p');
    const popupSelects = newPopupRow.getElementsByTagName('select');

    // trashCell.addEventListener('click', handleRemoveRow);
    trashCell.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (target.closest('.trash-cell')) {
        e.preventDefault();
        e.stopPropagation(); // Prevent any default action and event propagation

        const target = e.target as HTMLElement;
        const table = document.getElementById('invoice-table') as HTMLTableElement;
        const tbody = table.getElementsByTagName('tbody')[0];
        const trs = tbody.getElementsByTagName('tr');
        const popupTable = document.querySelector('.invoice-popup-right table') as HTMLTableElement;
        let hideTrash = false;

        if (trs.length > 1) {
          if (trs.length === 2) {
            hideTrash = true;
          }
          if (target.closest('.trash-cell')) { // Check if the click was on a trash cell
            const row = target.closest('tr') as HTMLTableRowElement;
            if (row) {
              const rowIndex = Array.from(row.parentElement!.children).indexOf(row);
              const popupTbody = popupTable.getElementsByTagName('tbody')[0];
              const popupRow = popupTbody.getElementsByTagName('tr')[rowIndex];
              const taxableAmount = row.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
              const tax = row.querySelector('input[name="tax[]"]') as HTMLInputElement;
              const amount = row.querySelector('input[name="amount[]"]') as HTMLInputElement;
              const tbvat = document.querySelector('.tb-vat') as HTMLElement;
              const tvat = document.querySelector('.t-vat') as HTMLElement;
              const totalAfterVAT = document.querySelector('.total-after-vat') as HTMLElement;

              tbvat.innerHTML = (parseFloat(removeSeparator(tbvat.textContent!)) - parseFloat(removeSeparator(taxableAmount.value))).toFixed(2);
              tvat.innerHTML = (parseFloat(removeSeparator(tvat.textContent!)) - parseFloat(removeSeparator(tax.value))).toFixed(2);
              totalAfterVAT.innerHTML = (parseFloat(removeSeparator(tbvat.innerHTML)) + parseFloat(removeSeparator(tvat.innerHTML))).toFixed(2);
              row.remove();
              popupRow.remove();
            }
          }
        }
        if (hideTrash) {
          const trashCells = table.getElementsByClassName('trash-cell');
          for (let i = 0; i < trashCells.length; i++) {
            trashCells[i].classList.add('overnight');
          }
        }
      }
    })
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].value = '';
      if (inputs[i].classList.contains('num_input')) {
        inputs[i].value = '0';
      }
      inputs[i].addEventListener('input', handleInputChange);
      if (inputs[i].name === 'unit_cost[]') {
        inputs[i].addEventListener('blur', (e) => {
          if (e.target) {
            const target = e.target as HTMLInputElement;
            target.value = addCommaAndSeparator(removeSeparator(target.value));
          }
        });
      }

    }

    for (let i = 0; i < popupInputs.length; i++) {
      popupPTags[i].innerHTML = '';
      if (popupPTags[i].getAttribute('data-name') === 'tax_rate[]') {
        popupPTags[i].innerHTML = '15%';
      }
      if (popupPTags[i].getAttribute('data-name') === 'discount_amount[]') {
        popupPTags[i].innerHTML = '0';
      }
      popupInputs[i].value = '';
      popupInputs[i].value = '';
      popupInputs[i].addEventListener('input', handleInputChange);
    }

    for (let i = 0; i < selects.length; i++) {
      selects[i].addEventListener('input', handleInputChange);
    }

    for (let i = 0; i < popupSelects.length; i++) {
      popupSelects[i].addEventListener('input', handleInputChange);
    }
    // const trashCell = newRow.querySelector('.trash-cell') as HTMLElement;
    // console.log(trashCell);
    // trashCell.classList.remove('overnight');
    // alert(trashCell.getElementsByTagName('img')[0].classList)

    tbody.appendChild(newRow);
    popupTbody.appendChild(newPopupRow);
    document.getElementById('submit-form')?.classList.add('inactive');
  }

  const serializeInvoice = () => {
    const invoiceElement = document.getElementById('invoice-right-section');
    if (!invoiceElement) return null;

    const inputs = invoiceElement.querySelectorAll('input, select') as NodeListOf<HTMLInputElement | HTMLSelectElement>;

    const data: Record<string, any> = {};

    inputs.forEach((input) => {
      const name = input.getAttribute('name');
      if (name) {
        data[name] = input.value || '';
      }
    });

    // Optionally capture the HTML structure (tags and text)
    const html = invoiceElement.outerHTML;

    return { data, html };
  };

  const handleInvoiceSubmit = async (e: React.FormEvent) => {
    setIsLoading(true);
    const discountInputs = e.currentTarget.querySelectorAll('input[name="discount[]"]') as NodeListOf<HTMLInputElement>;
    for (let i = 0; i < discountInputs.length; i++) {
      const discount = discountInputs[i];
      if (discount.value == '') {
        discount.value = '0';
      }
    }
    // if () {
    e.preventDefault();
    const serializedData = serializeInvoice();

    if (!serializedData) {
      console.error("Failed to serialize invoice data");
      return;
    }


    const submitForm = document.getElementById('submit-form') as HTMLFormElement;
    try {
      const formData = new FormData(e.target as HTMLFormElement);

      const data = {};
      formData.forEach((value, key) => {
        if (key.endsWith('[]')) {
          const name = key.slice(0, -2); // remove the '[]'
          if (!data[name]) {
            data[name] = [];
          }
          data[name].push(value);
        } else {
          data[key] = value;
        }
      });
      const clientElement = document.querySelector('.client-section p') as HTMLElement;
      const clientId = clientElement.getAttribute('data-id');
      const canvas = document.querySelector('canvas');
      const imageData = canvas?.toDataURL('image/png');

      data['invoice_type'] = selectedClient?.is_person === '1' ? 'NORMAL' : 'SIMPLE';
      data['client_id'] = clientId;
      data['created_by_id'] = user.id;
      data['workspace_id'] = selectedWorkspace ? selectedWorkspace.id : '';
      data['html'] = document.querySelector('#invoice-right-section')!.outerHTML;
      data['invoiceHTML'] = serializedData.data;
      data['invoiceData'] = serializedData.html;
      data['invoice_unique_code'] = invoiceUniqueCode;
      data['qrCodeImage'] = imageData;
      console.log('data', data);
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/create-invoice`;

      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }
      )

      // const invoiceView = document.querySelector('.invoice-popup-right') as HTMLElement;
      // const qrCodeDiv = document.createElement('div');
      // invoiceView.appendChild(qrCodeDiv);
      // qrCodeDiv.classList.add('qrcode-container');

      // const qrCodeImage = document.createElement('img');
      // qrCodeImage.classList.add('qr-code')
      // qrCodeImage.src = '/static/media/QRCode.d05e4a3290a8e9d13827.png';
      // qrCodeImage.alt = 'qr-code';
      // qrCodeDiv.appendChild(qrCodeImage);
      console.clear();
      console.log(response.data)
      const errorContainer = document.querySelector('.error-container') as HTMLElement;
      const errorContainerBody = errorContainer.querySelector('.error-container-body') as HTMLElement;
      errorContainer.classList.remove('overnight');
      if (response.data.errors.length > 0) {
        response.data.errors.forEach((error) => {
          errorContainerBody.innerHTML = '';
          const p = document.createElement('p');

          p.classList.add('error-container-points')
          p.innerHTML = `<span>${error.reason}</span>:   ${error.errorDescription}`;
          errorContainerBody.appendChild(p);
        })
        return;
      } else {
        setFormVisible(false);
        submitForm.classList.add('inactive');
        setAlertBody('Invoice has been created successfully!');
        setAlertStatus('success');
        setShowAlert(true);
        errorContainer.classList.add('overnight')
      }
      setInvoiceUniCode(response.data.invoice.uni_code);
      if (uniCodeParam) {
        setInvoiceUrl(process.env.REACT_APP_WEB_URL + uniCodeParam + '/invoice/proforma/' + response.data.invoice.uni_code);
      }
      setShowQRCode(true);
      qrCodeDisplay = true;
    } catch (error) {
      console.error('Error creating invoice', error);
      setAlertBody('Invoice has not been created!');
      setAlertStatus('error');
      setShowAlert(true);
    } finally {
      setIsLoading(false);
      submitForm.classList.remove('inactive');
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }

  const handleRemoveRow = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent any default action and event propagation

    const target = e.target as HTMLElement;
    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tbody = table.getElementsByTagName('tbody')[0];
    const trs = tbody.getElementsByTagName('tr');
    const popupTable = document.querySelector('.invoice-popup-right table') as HTMLTableElement;
    let hideTrash = false;

    if (trs.length > 1) {
      if (trs.length === 2) {
        hideTrash = true;
      }
      if (target.closest('.trash-cell')) { // Check if the click was on a trash cell
        const row = target.closest('tr') as HTMLTableRowElement;
        if (row) {
          const rowIndex = Array.from(row.parentElement!.children).indexOf(row);
          const popupTbody = popupTable.getElementsByTagName('tbody')[0];
          const popupRow = popupTbody.getElementsByTagName('tr')[rowIndex];
          const taxableAmount = row.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
          const amount = row.querySelector('input[name="amount[]"]') as HTMLInputElement;
          const tax = row.querySelector('input[name="tax[]"]') as HTMLInputElement;
          const tbvat = document.querySelector('.tb-vat') as HTMLElement;
          const tvat = document.querySelector('.t-vat') as HTMLElement;
          const totalAfterVAT = document.querySelector('.total-after-vat') as HTMLElement

          tbvat.innerHTML = (parseFloat(removeSeparator(tbvat.textContent!)) - parseFloat(removeSeparator(taxableAmount.value))).toFixed(2);
          tvat.innerHTML = (parseFloat(removeSeparator(tvat.textContent!)) - parseFloat(removeSeparator(tax.value))).toFixed(2);
          totalAfterVAT.innerHTML = (parseFloat(removeSeparator(tvat.innerHTML)) + parseFloat(removeSeparator(tbvat.innerHTML))).toFixed(2);

          row.remove();
          popupRow.remove();
        }
      }
    }
    if (hideTrash) {
      const trashCells = table.getElementsByClassName('trash-cell');
      for (let i = 0; i < trashCells.length; i++) {
        trashCells[i].classList.add('overnight');
      }
    }
  };

  const handleShowClientList = () => {
    const clientList = document.querySelector('.client-list') as HTMLElement;
    if (clientList.style.display == "") {
      clientList.style.display = 'block';
    } else {
      clientList.style.display = '';

    }

  }

  const handleShowClientForm = () => {
    const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
    const clientList = document.querySelector('.client-list') as HTMLElement;
    if (clientFormPopup.style.display == "") {
      clientFormPopup.style.display = 'block';
      clientList.style.display = '';
    } else {
      clientFormPopup.style.display = '';
      clientList.style.display = 'block';

    }
  }

  const handleHideClientForm = () => {
    const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
    const clientList = document.querySelector('.client-list') as HTMLElement;
    clientFormPopup.style.display = '';
    clientList.style.display = 'block';
  }

  const handleAddNewClient = async (e: React.FormEvent) => {
    e.preventDefault();
    const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
    const clientList = document.querySelector('.client-list') as HTMLElement;
    const name = (document.querySelector('.client-form-popup input[type="text"]') as HTMLInputElement).value;
    const email = (document.querySelector('.client-form-popup input[type="email"]') as HTMLInputElement).value;
    const data = { name, email, workspace_id: selectedWorkspace ? selectedWorkspace.id : '' };
    const clientFormSubmit = clientFormPopup.querySelector('.create-client-button') as HTMLButtonElement;
    clientFormSubmit.classList.add('inactive');
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/create-client`;
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setShowAlert(true);
      setAlertBody('Client has been added successfully!');
      setAlertStatus('success');
    } catch (error) {
      console.error('Error creating client', error);
      setShowAlert(true);
      setAlertBody('Client has not been added!');
      setAlertStatus('error');
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    clientFormPopup.style.display = '';
    clientList.style.display = 'block';

    const clientListUl = document.querySelector('.client-list ul') as HTMLElement;
    const li = document.createElement('li');
    li.addEventListener('click', handleShowSelectedClient);
    li.textContent = name + " - " + email;
    clientListUl.appendChild(li);

  }

  const handleClientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    const clientListUl = document.querySelector('.client-list ul') as HTMLElement;
    const lis = clientListUl.getElementsByTagName('li');
    for (let i = 0; i < lis.length; i++) {
      const li = lis[i];
      if (li.textContent!.toLowerCase().includes(search.toLowerCase())) {
        li.style.display = 'block';
      } else {
        li.style.display = 'none';
      }
    }
  }

  const handleShowSelectedClient = (e: MouseEvent | React.MouseEvent<HTMLLIElement>) => {
    const target = e.currentTarget as HTMLLIElement;
    const clientSection = document.querySelectorAll('.client-section') as NodeListOf<HTMLElement>;
    const clientElement = clientSection[0].querySelector('p')
    clientElement!.innerHTML = target.innerHTML.split('-')[0].trim();
    clientElement!.setAttribute('data-id', target.getAttribute('data-id')!);
    const clientList = document.querySelector('.client-list') as HTMLElement;
    const popupClient = document.getElementById('popup-client-name') as HTMLElement;
    setClient(target.innerHTML.split('-')[0].trim());
    popupClient.innerHTML = target.innerHTML.split('-')[0].trim();
    clientList.style.display = '';

    const clientId = target.getAttribute('data-id');

    clients.forEach(client => {
      if (client.id == parseInt(clientId!)) {
        setSelectedClient(client);
      }
    });

    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tableInputs = table.getElementsByTagName('input');
    let checkInputNull = false;
    for (let i = 0; i < tableInputs.length; i++) {
      const input = tableInputs[i];
      console.log(input.name + ": " + input.value);
      if (input.getAttribute('name') !== 'discount_amount[]' && input.value == '') {
        // console.log(input.getAttribute('name'));
        checkInputNull = true;
      }
    }

    const dueDateInput = document.getElementById('due-date-input') as HTMLInputElement

    if (dueDateInput.value == "" || clientElement?.getAttribute('data-id') == null) {
      checkInputNull = true;
    }

    console.log("number of inputs in the table is: " + tableInputs.length + " checkInputNull: " + checkInputNull);

    if (!checkInputNull) {
      setActiveSubmit(true)
    }
  }

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const invoiceNumber = document.getElementById('invoice-number') as HTMLInputElement;
    invoiceNumber.getElementsByTagName('p')[1].innerHTML = value;
  }
  // ** inputType may be 'issue-date' or 'due-date'
  const handleDateChange = (inputType: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const date = document.getElementById(inputType) as HTMLElement;

    date.getElementsByTagName('p')[1].innerHTML = value;

    if (inputType === 'issue-date') {
      setIssueDate(value);
    }

    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tableInputs = table.getElementsByTagName('input');
    let checkInputNull = false;
    for (let i = 0; i < tableInputs.length; i++) {
      const input = tableInputs[i];
      console.log(input.name + ": " + input.value);
      if (input.value == '') {
        checkInputNull = true;
      }
    }

    const dueDateInput = document.getElementById('due-date-input') as HTMLInputElement
    const clientSection = document.querySelectorAll('.client-section') as NodeListOf<HTMLElement>;
    const clientElement = clientSection[0].querySelector('p')

    if (dueDateInput.value == "" || clientElement?.getAttribute('data-id') == null) {
      checkInputNull = true;
    }

    console.log("number of inputs in the table is: " + tableInputs.length + " checkInputNull: " + checkInputNull);

    if (!checkInputNull) {
      setActiveSubmit(true)
    }
  }

  const handleSaleTypeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // const value = e.target.value;
    // const saleType = document.getElementById('sale-type') as HTMLElement;
    // saleType.getElementsByTagName('p')[1].innerHTML = value;

    const invoiceNumberInput = document.getElementById('invoice-number-input') as HTMLInputElement;
    const popupInvoiceNumber = document.getElementById('popup-invoice-number') as HTMLElement;
    const popupTitle = document.getElementById('popup-title') as HTMLElement;
    const invoiceNumber = invoiceNumberInput.value;
    let newInvoiceNumber = invoiceNumber.substring(invoiceNumber.indexOf('-'), invoiceNumber.length);
    if (e.target.value === 'CREDIT NOTE') {
      newInvoiceNumber = 'CN' + newInvoiceNumber;
      popupTitle.innerHTML = 'CREDIT NOTE';
    } else if (e.target.value === 'DEBIT NOTE') {
      newInvoiceNumber = 'DN' + newInvoiceNumber;
      popupTitle.innerHTML = 'DEBIT NOTE';
    } else if (e.target.value === 'PROFORMA INVOICE') {
      const taxRate = document.querySelectorAll('select[name="tax_rate[]"]') as NodeListOf<HTMLSelectElement>;
      taxRate.forEach(rate => {
        rate.value = '0';
        const popupTable = document.querySelector('.invoice-popup-right table') as HTMLTableElement;
        const popupTaxRate = popupTable.querySelectorAll('select[name="tax_rate[]"]') as NodeListOf<HTMLInputElement>;
        const popupTaxRateP = popupTable.querySelectorAll('p[data-name="tax_rate[]"]') as NodeListOf<HTMLInputElement>;
        console.log(popupTable);
        console.log(popupTaxRate[0]);
        popupTaxRate[0].value = '0';
        popupTaxRateP[0].innerHTML = '0%';
      });
      newInvoiceNumber = 'PO' + newInvoiceNumber;
      popupTitle.innerHTML = 'PROFORMA INVOICE';
    } else {
      popupTitle.innerHTML = 'VAT INVOICE';
      newInvoiceNumber = 'INV' + newInvoiceNumber;
    }
    invoiceNumberInput.value = newInvoiceNumber;
    popupInvoiceNumber.innerHTML = newInvoiceNumber;
    setInvoiceNumber(newInvoiceNumber)
  }

  useEffect(() => {
    console.log('clients', clients);
  }, [clients]);

  useEffect(() => {
    console.log('selectedClient', selectedClient);
  }, [selectedClient]);
  const handleInputChange = (e: Event) => {
    setActiveSubmit(false);
    const target = e.target as HTMLInputElement;
    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tableInputs = table.getElementsByTagName('input');
    const row = target.closest('tr') as HTMLTableRowElement;
    // const inputs = row.getElementsByTagName('input');
    // get the index of the row
    const rowIndex = Array.from(row.parentElement!.children).indexOf(row);
    const name = target.name;
    const value = target.value;
    const invoiceView = document.querySelector('.invoice-popup-right') as HTMLElement;
    const popuptable = invoiceView.querySelector('table') as HTMLTableElement;
    const popupTbody = popuptable.getElementsByTagName('tbody')[0];
    const popupRow = popupTbody.getElementsByTagName('tr')[rowIndex];
    const popupTaxableAmountInput = popupRow.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
    const popupTaxableAmountPTag = popupRow.querySelector('p[data-name="taxable_amount[]"]') as HTMLInputElement;
    const popupInputs = popupRow.getElementsByTagName('input');
    const pTags = popupRow.getElementsByTagName('p');
    let checkInputNull = false;

    // for (let i = 0; i < popupInputs.length; i++) {
    //   if (popupInputs[i].name === name) {
    //     if (popupInputs[i].name == "unit_cost[]") {
    //       popupInputs[i].value = addCommaAndSeparator(removeSeparator(value));
    //     } else {
    //       popupInputs[i].value = value;
    //     }
    //   }
    // }

    for (let i = 0; i < popupInputs.length; i++) {
      if (pTags[i].getAttribute('data-name') === name) {
        if (pTags[i].getAttribute('data-name') == "unit_cost[]") {
          pTags[i].innerHTML = addCommaAndSeparator(removeSeparator(value));
        } else if (pTags[i]?.getAttribute('data-name') == "tax_rate[]") {
          pTags[i].innerHTML = value + "%";
        } else {
          pTags[i].innerHTML = value;
        }
      }
    }

    if (name === 'unit_cost[]' || name === 'quantity[]') {
      const taxableAmount = row.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
      const unitCost = row.querySelector('input[name="unit_cost[]"]') as HTMLInputElement;
      const quantity = row.querySelector('input[name="quantity[]"]') as HTMLInputElement;
      const taxableAmountValue = (parseFloat(removeSeparator(unitCost.value)) * parseFloat(removeSeparator(quantity.value))).toString()

      if (!isNaN(parseFloat(taxableAmountValue))) {
        taxableAmount.value = addCommaAndSeparator(removeSeparator(taxableAmountValue));
      } else {
        taxableAmount.value = "0";
      }

      if (popupTaxableAmountInput) {
        popupTaxableAmountInput.value = taxableAmountValue;
        if (!isNaN(parseFloat(taxableAmountValue))) {
          popupTaxableAmountInput.value = addCommaAndSeparator(taxableAmountValue);
          popupTaxableAmountPTag.innerHTML = addCommaAndSeparator(taxableAmountValue);
        } else {
          popupTaxableAmountInput.value = "0";
          popupTaxableAmountPTag.innerHTML = "0";
        }
      }
    }
    if (name === 'quantity[]' || name === 'unit_cost[]' || name === 'tax_rate[]' || name === 'discount_amount[]') {

      updateTaxAndAmount(rowIndex, popupRow);
    }

    for (let i = 0; i < tableInputs.length; i++) {
      const input = tableInputs[i];
      console.log(input.name + ": " + input.value);
      if (input.getAttribute('name') !== 'discount_amount[]' && input.value == '') {
        checkInputNull = true;
      }
    }

    const dueDateInput = document.getElementById('due-date-input') as HTMLInputElement
    const clientSection = document.querySelectorAll('.client-section') as NodeListOf<HTMLElement>;
    const clientElement = clientSection[0].querySelector('p')

    if (dueDateInput.value == "" || clientElement?.getAttribute('data-id') == null) {
      checkInputNull = true;
    }

    console.log("number of inputs in the table is: " + tableInputs.length + " checkInputNull: " + checkInputNull);

    if (!checkInputNull) {
      setActiveSubmit(true)
    }

  }

  const handleReactInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setActiveSubmit(false);
    if (e.target.name === 'quantity[]') {
      if (parseFloat(e.target.value) < 0) {
        e.target.value = '';
      }
    }
    console.log('reactinput');
    const target = e.target;
    const row = target.closest('tr') as HTMLTableRowElement;
    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tableInputs = table.getElementsByTagName('input');
    const inputs = row.getElementsByTagName('input');

    // Get the index of the row
    const rowIndex = Array.from(row.parentElement!.children).indexOf(row);
    console.log(row);
    console.log(rowIndex);

    const name = target.name;
    console.log(name);
    let value = target.value;
    const invoiceView = document.querySelector('.invoice-popup-right') as HTMLElement;
    const popupTable = invoiceView.querySelector('table') as HTMLTableElement;
    const popupTbody = popupTable.getElementsByTagName('tbody')[0];
    const popupRow = popupTbody.getElementsByTagName('tr')[rowIndex];
    const popupInputs = popupRow.getElementsByTagName('input');
    const pTags = popupRow.getElementsByTagName('p');
    const popupTaxableAmountInput = popupRow.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
    const popupTaxableAmountPTag = popupRow.querySelector('p[data-name="taxable_amount[]"]') as HTMLElement;
    const popupDiscountPTag = popupRow.querySelector('p[data-name="discount_amount[]"]') as HTMLElement;
    let checkInputNull = false;

    // for (let i = 0; i < popupInputs.length; i++) {
    //   // console.log(popupInputs[i].name + ": " + popupInputs[i].value);
    //   if (popupInputs[i].name === name) {
    //     if (popupInputs[i].name == "unit_cost[]") {
    //       popupInputs[i].value = addCommaAndSeparator(removeSeparator(value));
    //     } else {
    //       popupInputs[i].value = value;
    //     }
    //   }
    // }
    for (let i = 0; i < inputs.length; i++) {
      // console.log(popupInputs[i].name + ": " + popupInputs[i].value);
      // alert(name)
      if (pTags[i]?.getAttribute('data-name') === name) {
        if (pTags[i]?.getAttribute('data-name') == "unit_cost[]") {
          pTags[i].innerHTML = addCommaAndSeparator(removeSeparator(value));
        } else if (pTags[i]?.getAttribute('data-name') == "tax_rate[]") {
          pTags[i].innerHTML = value + "%";
        } else if (pTags[i]?.getAttribute('data-name') == "discount_amount[]") {
          value = addCommaAndSeparator(removeSeparator(value))
          pTags[i].innerHTML = value;
          setDiscount(parseInt(value));
        } else {
          pTags[i].innerHTML = value;
        }
      }
    }
    if (name === 'unit_cost[]' || name === 'quantity[]') {
      const taxableAmount = row.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
      const unitCost = row.querySelector('input[name="unit_cost[]"]') as HTMLInputElement;
      const quantity = row.querySelector('input[name="quantity[]"]') as HTMLInputElement;
      const taxableAmountValue = (parseFloat(removeSeparator(unitCost.value)) * parseFloat(removeSeparator(quantity.value))).toString()

      if (!isNaN(parseFloat(taxableAmountValue))) {
        taxableAmount.value = addCommaAndSeparator(removeSeparator(taxableAmountValue));
      } else {
        taxableAmount.value = "0";
      }

      // if (popupTaxableAmountInput) {
      //   popupTaxableAmountInput.value = taxableAmountValue;
      //   if (!isNaN(parseFloat(taxableAmountValue))) {
      //     popupTaxableAmountInput.value = addCommaAndSeparator(taxableAmountValue);
      //   } else {
      //     popupTaxableAmountInput.value = "0";
      //   }
      // }
      if (popupTaxableAmountPTag) {
        popupTaxableAmountPTag.innerHTML = taxableAmountValue;
        if (!isNaN(parseFloat(taxableAmountValue))) {
          popupTaxableAmountPTag.innerHTML = addCommaAndSeparator(taxableAmountValue);
        } else {
          popupTaxableAmountPTag.innerHTML = "0";
        }
      }
    }

    if (name === 'quantity[]' || name === 'unit_cost[]' || name === 'tax_rate[]' || name === 'discount_amount[]') {
      updateTaxAndAmount(rowIndex, popupRow);
    }

    for (let i = 0; i < tableInputs.length; i++) {
      const input = tableInputs[i];
      console.log(input.name + ": " + input.value);
      if (input.getAttribute('name') !== 'discount_amount[]' && input.value == '') {
        checkInputNull = true;
      }
    }

    const dueDateInput = document.getElementById('due-date-input') as HTMLInputElement
    const clientSection = document.querySelectorAll('.client-section') as NodeListOf<HTMLElement>;
    const clientElement = clientSection[0].querySelector('p')
    console.clear();
    console.log('dueDateInput.value: ' + dueDateInput.value, 'clientElement?.getAttribute("data-id"): ' + clientElement?.getAttribute('data-id'));
    if (dueDateInput.value == "" || clientElement?.getAttribute('data-id') == null) {
      checkInputNull = true;
    }

    console.log("number of inputs in the table is: " + tableInputs.length + " checkInputNull: " + checkInputNull);

    if (!checkInputNull) {
      setActiveSubmit(true)
    }
  };

  const netAmountTotal = (sumTaxableAmount, discount) => {
    return sumTaxableAmount - discount;
  }
  const updateTaxAndAmount = (rowIndex, popupRow: HTMLTableRowElement) => {
    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tbody = table.getElementsByTagName('tbody')[0];
    const row = tbody.getElementsByTagName('tr')[rowIndex]
    const inputs = row.getElementsByTagName('input')
    const tax = row.querySelector('input[name="tax[]"]') as HTMLInputElement;
    // const popupTax = popupRow.querySelector('input[name="tax[]"]') as HTMLInputElement;
    const popupTax = popupRow.querySelector('p[data-name="tax[]"]') as HTMLInputElement;

    const taxRate = row.querySelector('select[name="tax_rate[]"]') as HTMLSelectElement;
    const taxRateValue = taxRate.value;
    // const taxValue = tax.value;
    const taxableAmount = row.querySelector('input[name="taxable_amount[]"]') as HTMLInputElement;
    const taxableAmountValue = taxableAmount.value
    const amount = row.querySelector('input[name="amount[]"]') as HTMLInputElement;
    // const popupAmount = popupRow.querySelector('input[name="amount[]"]') as HTMLInputElement;
    const popupAmount = popupRow.querySelector('p[data-name="amount[]"]') as HTMLInputElement;
    const taxableAmounts = table.querySelectorAll('input[name="taxable_amount[]"]') as NodeListOf<HTMLInputElement>;

    let taxValue = (parseFloat(removeSeparator(taxableAmountValue)) / 100) * parseFloat(removeSeparator(taxRateValue))
    if (taxableAmount.value == "") {
      tax.value = "";
      // popupTax.value = "";
      amount.value = "";
      // popupAmount.value = "";
      popupTax.innerHTML = "";
      amount.innerHTML = "";
      popupAmount.innerHTML = "";
    } else {
      const discountInput = row.querySelector('input[name="discount_amount[]"]') as HTMLInputElement;
      const discountValue = discountInput.value;
      let sumTaxableAmount = taxValue + parseFloat(removeSeparator(taxableAmountValue))
      tax.value = addCommaAndSeparator(taxValue.toString());
      // popupTax.value = addCommaAndSeparator(taxValue.toString());
      amount.value = addCommaAndSeparator(netAmountTotal(sumTaxableAmount, discountValue))
      // popupAmount.value = addCommaAndSeparator(netAmountTotal(sumTaxableAmount, discountValue))
      popupTax.innerHTML = addCommaAndSeparator(taxValue.toString());
      amount.innerHTML = addCommaAndSeparator(netAmountTotal(sumTaxableAmount, discountValue))
      popupAmount.innerHTML = addCommaAndSeparator(netAmountTotal(sumTaxableAmount, discountValue))
    }

    const tbVATElement = document.querySelector('.tb-vat') as HTMLElement;
    const tVATElement = document.querySelector('.t-vat') as HTMLElement;
    const totalInvoiceElement = document.querySelector('.total-invoice') as HTMLElement;
    let totalTBVAT = 0;
    let totalTVAT = 0;
    let discountAmount = 0;
    if (taxableAmounts) {
      taxableAmounts.forEach(taxableAmount => {
        const taxableAmountValue = parseFloat(removeSeparator(taxableAmount.value));
        const amount = taxableAmount.closest('tr')?.querySelector('input[name="amount[]"]') as HTMLInputElement
        const tax = taxableAmount.closest('tr')?.querySelector('input[name="tax[]"]') as HTMLInputElement
        const discountInput = taxableAmount.closest('tr')?.querySelector('.discount') as HTMLInputElement;
        if (!isNaN(taxableAmountValue)) {
          totalTBVAT += taxableAmountValue;
          // console.clear();
          console.log('amount: ' + amount.value, 'tax: ' + tax.value);
          totalTVAT += (parseFloat(removeSeparator(tax.value)));
          discountAmount += parseFloat(removeSeparator(discountInput.value))
        }
      });

    }
    tbVATElement.textContent = addCommaAndSeparator(totalTBVAT);
    tVATElement.textContent = addCommaAndSeparator(totalTVAT);
    // console.clear();
    // console.log(totalTBVAT + " " + totalTVAT + " " + discountAmount);
    totalInvoiceElement.textContent = addCommaAndSeparator(totalTBVAT + totalTVAT - discountAmount);
    setTotalInvoice(addCommaAndSeparator(totalTBVAT + totalTVAT - discountAmount))

  }

  const handleBlurInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const target = e.target;
    target.value = addCommaAndSeparator(removeSeparator(target.value));
  }

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const note = document.getElementById('popup-note') as HTMLElement;
    note.innerHTML = value.length > 60 ? value.substring(0, 60) + '...' : value;
  }

  const handleTestZatca = async (e: React.FormEvent) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/test-zatca`;
    try {
      setIsLoading(true);
      const response = await axios.post(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error testing ZATCA', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSelectInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const target = e.target as HTMLElement;
    const input = target.closest('input') as HTMLInputElement;
    // select all the content of the input
    input.select();
  }

  const handleHideErrorContainer = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    const errorContainer = target.closest('.error-container') as HTMLElement;
    errorContainer.classList.toggle('overnight');
  }

  return (
    <div className='flex-container flex-start'>
      {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      <div id="invoice-left-section" className='w-85'>
        <div id='invoice-form-container' className={`${!formVisible || qrCodeDisplay ? 'overnight' : ''}`}>
          <form id='invoice-form' onSubmit={handleInvoiceSubmit}>
            <input type="hidden" name="invoiceQ_reference_number" value={invoiceQReferenceNumber || ''} />
            <div className="flex">
              <div id="invoice-info" className='invoice-info'>
                <div className="flex position-relative align-items-center">
                  <p>Client</p>
                  <div className="info-section client-section m-l-3 position-relative">
                    <p className='w-100' onClick={handleShowClientList}>SELECT FROM CLIENTS</p>
                    <div className="client-list rounded-radius">
                      <div className='add-new-client' onClick={handleShowClientForm}>+ ADD A NEW CLIENT</div>
                      <input type="text" name="" id="search-client" placeholder='Search...' onChange={handleClientSearch} />
                      <ul>
                        {
                          clients.map(client => (
                            <li key={client.id} data-id={client.id} onClick={handleShowSelectedClient}>{client.name} {client.email !== 'from@DatabaseSeeder.com' ? ' - ' + client.email : ''}</li>
                          ))
                        }
                      </ul>
                    </div>
                    <div className="client-form-popup client-list rounded-radius">
                      <span className='client-form-close' onClick={handleHideClientForm}>X</span>
                      <div className='m-t-3'>
                        <input type="text" placeholder='Full Name' />
                        <input type="email" placeholder='Email' />
                        <input type="button" className='clickable create-client-button' value="CREATE CLIENT" onClick={handleAddNewClient} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Invoice #</p>
                  <div className="info-section m-l-3">
                    <input type="text" name="invoice_number" id="invoice-number-input" placeholder='INV-######' value={invoiceNumber} onChange={handleInvoiceNumberChange} readOnly />
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Issue Date</p>
                  <div className="info-section m-l-3">
                    <input type="date" name="issue_date" id="issue-date-input" onChange={handleDateChange("issue-date")} />
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Due Date</p>
                  <div className="info-section m-l-3">
                    {/* <input type="date" name="due_date" id="due-date-input" onChange={handleDateChange("due-date")} /> */}
                    <input type="date" name="due_date" id="due-date-input" onChange={handleDateChange("due-date")} />
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Currency</p>
                  <div className="info-section m-l-3">
                    <input type="text" name="currency" id="currency" placeholder='Currency' onChange={handleNoteChange} value={selectedClient ? selectedClient.currency : ''} readOnly />
                  </div>
                </div>
              </div>

              <div id="invoice-info-right" className='invoice-info'>
                <div className="invoice-info-row flex align-items-center">
                  <p>Invoice Type</p>
                  <div className="info-section m-l-3">
                    <select name="invoice_type" id="invoice-type-select" className='disabled' onChange={handleSaleTypeChange} >
                      <option value="NORMAL" selected={selectedClient?.is_person === '1'}>Normal - B2B</option>
                      <option value="SIMPLIFIED" selected={selectedClient?.is_person !== '0'}>Simplified - B2C</option>
                    </select>
                  </div>
                </div>
                <div className="invoice-info-row flex align-items-center">
                  <p>Sale Type</p>
                  <div className="info-section m-l-3">
                    <select name="sale_type" id="sale-type-select" className='uppercase disabled' onChange={handleSaleTypeChange}>
                      <option selected={invoiceType == 'vat' ? true : false} value="VAT INVOICE">VAT INVOICE</option>
                      <option selected={invoiceType == 'proforma' ? true : false} value="PROFORMA INVOICE">PROFORMA INVOICE</option>
                      <option selected={invoiceType == 'credit' ? true : false} value="CREDIT INVOICE">credit INVOICE</option>
                      <option selected={invoiceType == 'debit' ? true : false} value="DEBIT INVOICE">debit INVOICE</option>
                    </select>
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Po Reference</p>
                  <div className="info-section m-l-3">
                    <input type="text" name="po_reference" id="po-reference" placeholder='####' />
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Note</p>
                  <div className="info-section m-l-3">
                    <input type="text" name="note" id="note" placeholder='Note...' onChange={handleNoteChange} />
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Advanced Payment</p>
                  <div className="info-section m-l-3">
                    <input type="text" name="total_advanced_paid_amount" id="total_advanced_paid_amount" placeholder='Advanced Payment' />
                  </div>
                </div>
                {/* <div className="invoice-info-row flex align-items-center">
                  <p>Supply Date</p>
                  <div className="info-section m-l-3">
                    <input type="date" name="supply_date" id="supply-date-input" />
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Currency</p>
                  <div className="info-section m-l-3">
                    <input type="text" name="currency-iso-code" id="currency-input" value='SAR' />
                  </div>
                </div> */}

                {/* <div className="invoice-info-row flex align-items-center">
                  <p>Duplicate Mode</p>
                  <div className="info-section m-l-3">
                    <select name="duplicate_mode" id="duplicate-mode-select" onChange={handleSaleTypeChange}>
                      <option value="REJECT_WITH_FAILURE">REJECT WITH FAILURE</option>
                      <option value="RETRIEVE_DUPLICATE_DETAILS">RETRIEVE DUPLICATE DETAILS</option>
                    </select>
                  </div>
                </div>

                <div className="invoice-info-row flex align-items-center">
                  <p>Narration</p>
                  <div className="info-section m-l-3">
                    <input type="text" name="narration" id="narration" placeholder='Narration...' onChange={handleNoteChange} />
                  </div>
                </div> */}
              </div>
            </div>

            {/* Invoice Table */}
            <table id="invoice-table" className='mt-4'>
              <thead>
                <tr>
                  <th>
                    Name
                  </th>
                  <th>Description</th>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Qty</th>
                  <th className='group relative'>
                    Cost
                    <span className="absolute left-0 top-full hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                      Unit Cost
                    </span>
                  </th>
                  <th className='group relative'>
                    T.Amount
                    <span className="absolute left-0 top-full hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                      Taxable Amount
                    </span>
                  </th>
                  <th>TAX%</th>
                  <th>TAX</th>
                  <th>Discount</th>
                  <th className='group relative'>
                    N.Amount
                    <span className="absolute left-0 top-full hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                      Taxable Amount
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='invoice-table-td product-name'>
                    <input type="text" name='product_name[]' placeholder='Product Name' onChange={handleReactInputChange} />
                  </td>
                  <td className='invoice-table-td item-desc'>
                    <input type="text" name='item_description[]' placeholder='Item Description' onChange={handleReactInputChange} />
                  </td>
                  <td className='invoice-table-td product-code'>
                    <input type="text" name='product_code[]' placeholder='Code' onChange={handleReactInputChange} />
                  </td>
                  <td className='invoice-table-td unit-type'>
                    <input type="text" name='unit_type[]' placeholder='type' onChange={handleReactInputChange} />
                  </td>
                  <td className='invoice-table-td qty'>
                    <input type="text" name='quantity[]' className='num_input' placeholder='Qty' onChange={handleReactInputChange} />
                  </td>
                  <td className='invoice-table-td unitCost'>
                    <input type="text" name='unit_cost[]' className='num_input' placeholder='Unit Amount' onChange={handleReactInputChange} onBlur={handleBlurInput} />
                  </td>
                  <td className='invoice-table-td taxable'>
                    <input type="text" name='taxable_amount[]' className='inactive num_input' placeholder='Taxable Amount' readOnly onChange={handleReactInputChange} />
                  </td>
                  <td className='invoice-table-td tax-percentage'>
                    <select name="tax_rate[]" id="" className="tax-rate" onChange={handleReactInputChange}>
                      <option value="15">15%</option>
                      <option value="0">0%</option>
                    </select>
                    <select name="reason" id="" className="reason overnight">
                      <option value="">Select a reason</option>
                      <option value="One">One</option>
                      <option value="Two">Two</option>
                    </select>
                  </td>
                  <td className='invoice-table-td tax'>
                    <input type="text" name='tax[]' placeholder='TAX' className='inactive num_input' readOnly onChange={handleReactInputChange} />
                  </td>
                  <td className='invoice-table-td discount-amount'>
                    <input type="text" name='discount_amount[]' className='discount num_input' placeholder='0' value={discount} onFocus={handleSelectInput} onChange={handleReactInputChange} />
                  </td>
                  <td className='invoice-table-td amount'>
                    <input type="text" name="amount[]" placeholder='Amount num_input' className='inactive' readOnly onChange={handleReactInputChange} />
                  </td>
                  <td className='trash-cell overnight'>
                    <button type="button" onClick={handleRemoveRow}>
                      <img src={Trash} alt="trash" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-container flex-end">
              <button id='add-record' onClick={handleAddRecord}>
                ADD A RECORD
              </button>
            </div>
            {/* ${activeSubmit ? '' : 'inactive'} */}
            <input type="submit" id='submit-form' className={`btn btn-dark ${activeSubmit ? '' : 'inactive'}`} value="Create Invoice" />
            {
              isloading === true ? <p className="bold-text" style={
                {
                  'color': '#94ddf3',
                  'display': 'inline-block',
                  'marginLeft': '1rem',
                  'fontSize': '2rem',
                }}>
                Loading...
              </p> : ""
            }
          </form>
        </div>
        {!formVisible || qrCodeDisplay ?
          <InvoiceIssued
            invoiceNumber={invoiceNumber}
            issueDate={issueDate}
            clientName={client}
            invoiceTotal={totalInvoice}
            separatePage={false}
            invoiceUrl={invoiceUrl}
            showQRCode={true}
            legalName={legalName}
            companyName={selectedWorkspace.name}
            invoiceType={invoiceType === "PROFORMA INVOICE" ? "PROFORMA INVOICE" : "VAT INVOICE"}
          /> : ""}
      </div>


      <InvoiceView
        qrCodeDisplay={qrCodeDisplay}
        invoiceUrl={invoiceUrl}
        showQRCode={showQRCode}
        invoiceUniCode={invoiceUniCode}
        bankInfo={bankInfo}
        workspaceAddress={workspaceAddress}
        selectedWorkspace={selectedWorkspace}
        legalName={legalName}
        invoiceType={invoiceType === "proforma" ? "PROFORMA INVOICE" : "VAT INVOICE"}
      />
      <div className="error-container overnight">
        <FontAwesomeIcon icon={faTimesCircle} className='error-container-close' onClick={handleHideErrorContainer} />
        <div className="error-container-body"></div>
      </div>
    </div>
  )
}

export default React.memo(Invoice);