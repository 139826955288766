import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const Vendor = () => {
  const vendorId = useParams().vendorId;
  const [itineraryDetail, setItineraryDetail] = useState([]);
  const navigate = useNavigate();

  const fetchInitialData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/vendor/itineraryDetail/${vendorId}`;
    const response = await axios.get(url,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
    setItineraryDetail(response.data.itineraryDetail);
    console.clear();
    console.log('data', response.data.itineraryDetail);
  }

  const navigateToItinerary = (refUnique) => {
    // navigate('/ttp/itinerary/{refUnique})
    navigate(`/ttp-itinerary/R6AmEkD4H/${refUnique}`);
  }

  useEffect(() => {
    fetchInitialData();
  }, []);
  return (
    <>
      <TableContainer component={Paper} style={{ height: '100vh', marginTop: '0px', overflowY: 'auto' }}>
        <Table id='itinerary-details-table' stickyHeader>
          <TableHead>
            <TableRow className="table-header">
              <TableCell padding="none"></TableCell>
              <TableCell className='custom-header-cell align-center i-border-r sticky left-0 group' data-name='vendor'>experience</TableCell>
              <TableCell className='custom-header-cell align-center i-border-r sticky left-0 group' data-name='experience'>description</TableCell>
              <TableCell className='custom-header-cell align-center i-border-r sticky left-0 group' data-name='value'># Units</TableCell>
              <TableCell className='custom-header-cell align-center i-border-r sticky left-0 group' data-name='value'>rate net vat</TableCell>
              <TableCell className='custom-header-cell align-center i-border-r sticky left-0 group' data-name='value'>RU</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              itineraryDetail.map((detail: any) => (
                <TableRow key={detail.id}>
                  <TableCell padding="none">
                    <IconButton>
                      <ExpandMore />
                    </IconButton>
                  </TableCell>
                  <TableCell className='align-center i-border-r'>{detail.experience}</TableCell>
                  <TableCell className='align-center i-border-r'>{detail.description}</TableCell>
                  <TableCell className='align-center i-border-r'>{detail.number_of_units}</TableCell>
                  <TableCell className='align-center i-border-r'>{detail.rate_net_vat}</TableCell>
                  <TableCell className='align-center i-border-r' onClick={(e) => navigateToItinerary(detail.itinerary.ref_unique)}>{detail.itinerary.ref_unique}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Vendor