import { useSelector } from "react-redux";
import { RootState } from "../store";


const useRoleAccess = (role: string) => {
  const userRole = useSelector((state: RootState) => state.userState.role);
  // if (role === 'Administrator') {
  // }
  return userRole.toLowerCase() === role.toLowerCase();
};

export default useRoleAccess;
