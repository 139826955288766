import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect, useState } from 'react';
import Country from '../shapes/Country';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Client } from '../../types/Client';
import { ClientAddress } from '../../types/ClientAddress';
import PopDropdown from '../shapes/PopDropdown';
import CustomizedSelect from '../shapes/CustomizedSelect';

interface Props {
  client: Client | null;
  clientAddress: ClientAddress | null;
  handleClientInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClientAddressInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCountryChange: (newValue: string) => void;
  handleClientsChange: (clients: Client[]) => void;
}



const ClientPopup: React.FC<Props> = ({ client, clientAddress, handleClientInputChange, handleClientAddressInputChange, handleCountryChange, handleClientsChange }) => {
  const param = useParams();
  const uniCode = param.uniCode;
  const token = localStorage.getItem('token');
  const [vatTreatment, setVatTreatment] = useState<string>('not_registered');
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [selectedIsPerson, setSelectedIsPerson] = useState<string>("No");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("SAR");
  const [selectedPreferredChannel, setSelectedPreferredChannel] = useState<string>("NO_NOTIFICATION");

  const isPersonOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  const currencyOptions = [
    { value: 'SAR', label: 'SAR' },
    { value: 'USD', label: 'USD' },
  ];

  const preferredChannelOptions = [
    { value: 'NO_NOTIFICATION', label: 'NO_NOTIFICATION' },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clientPopup = document.getElementById('client-popup');
      const isReactSelect = (event.target as HTMLElement).closest('.css-1nmdiq5-menu');

      if (clientPopup) {
        const trIsClicked = (event.target as HTMLElement).parentElement?.classList.contains('client-tr');
        const isAddClient = (event.target as HTMLElement).classList.contains('add-client');

        const isChild = clientPopup.contains(event.target as Node);

        if (!isAddClient && !isReactSelect && event.target !== clientPopup && !trIsClicked && !isChild) {
          if (clientPopup.classList.contains('show')) {
            clientPopup.classList.remove('show');
          }
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleVatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVatTreatment(e.target.value); // Update state based on selected radio button
  };

  const handleIsPersonChange = (newValue: string) => {
    setSelectedIsPerson(newValue);
  };

  const handleCurrencyChange = (newValue: string) => {
    setSelectedCurrency(newValue);
  };

  useEffect(() => {
    if (client && client.vatTreatment) {
      console.log('client useEffect:', client);
      setVatTreatment(client.vatTreatment)
    }
  }, [client]);

  const handleRadioCheck = (e: React.MouseEvent<HTMLParagraphElement>) => {
    const target = e.target as HTMLElement;
    const vatTreatment = document.querySelectorAll('.vat-treatment-radio');
    vatTreatment.forEach((treatment) => {
      treatment.removeAttribute('checked');
    });
    const radio = target.previousElementSibling as HTMLInputElement;
    radio.click();
    target.previousElementSibling?.setAttribute('checked', 'checked');
  }

  const addClient = async (data) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/add-client`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data', // Specify content type for form data
        },
      });
      setAlertBody('Client added successfully');
      setAlertStatus('success');
      setShowAlert(true);
      console.log(response.data);
      handleClientsChange(response.data.clients)
    } catch (error) {
      console.error('Error adding client:', error);
      setAlertBody('Client was not added');
      setAlertStatus('error');
      setShowAlert(true);
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      const clientPopup = document.getElementById('client-popup') as HTMLElement;
      if (clientPopup) {
        clientPopup.classList.remove('show'); // Add the 'show' class to slide in the popup
      }
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    formData.set('vat_treatment', vatTreatment);

    // inputs.forEach((input) => {
    //   formData.append(input.name, input.value);
    // });
    // selects.forEach((select) => {
    //   formData.append(select.name, select.value);
    // });
    if (uniCode) {
      formData.set('uniCode', uniCode);
    }
    console.clear();
    console.log(formData);
    addClient(formData); // Pass formData variable, not FormData class
  };



  return (
    <div id='client-popup' className='extra-popup p-t-3 overflow-auto'>
      <h5><b>Contact</b></h5>
      <p className='sub-title'>A person or organization you do business with</p>
      <div className="light-blue_bg highlighted-section">
        Business and VAT Treatment Required
      </div>
      <form id="client-form" onSubmit={handleFormSubmit}>
        <input type="hidden" value={uniCode} />
        <input type="hidden" name="client_id" value={client ? client.id : ''} />
        <div className="client-form-container">
          <div className="flex-container">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Client name <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="client_name" data-name='name' className='form-control w-100' value={client ? client.name : ''} required onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Arabic name <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="arabicName" data-name='arabic-name' className='form-control w-100' value={client ? client.arabicName : ''} required onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Contact person name
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="contactPersonName" data-name='contact-person-name' placeholder='Optional' className='form-control w-100' value={client ? client.contactPersonName : ''} onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Contact person mobile
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="contactPersonMobile" data-name='contact-person-mobile' placeholder='Optional' className='form-control w-100' value={client ? client.contactPersonMobile : ''} onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Preferred channel
              </p>
            </div>
            <div className="flex-2-3">
              <CustomizedSelect
                elementName='preferredChannel'
                items={preferredChannelOptions}
                value={selectedPreferredChannel}
                handleChange={handleIsPersonChange}
                placeholder="Select a country..."
              />
            </div>
          </div>
          <div className="flex-container country-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Country
              </p>
            </div>
            <div className="flex-2-3">
              <Country
                id={null}
                country={clientAddress?.country}
                handleCountryChange={handleCountryChange}
                withRequest={false}
              />
            </div>
          </div>
          <div className="vat-treatment-container flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center h-100">
              <p>
                VAT treatment
              </p>
            </div>
            <div className="flex-2-3 flex gap-1 h-100">
              <div className="vat-treatment">
                <input
                  type="radio"
                  name="vat_treatment"
                  className='vat-treatment-radio'
                  value="not_registered"
                  checked={vatTreatment === 'not_registered'}
                  onChange={handleVatChange} required
                />
                <p onClick={handleRadioCheck}>
                  Not VAT
                </p>
              </div>

              <div className="vat-treatment">
                <input
                  type="radio"
                  name="vat_treatment"
                  className='vat-treatment-radio'
                  value="registered"
                  checked={vatTreatment === 'registered'}
                  onChange={handleVatChange} required
                />
                <p onClick={handleRadioCheck}>
                  VAT registered in KSA
                </p>
              </div>
            </div>
          </div>
          <div className="flex-container">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Tax registration number
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="trn" placeholder='Optional' className='form-control w-100' value={client ? client.trn : ''} onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Registration number <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="registrationNumber" required className='form-control w-100' value={client ? client.registrationNumber : ''} onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Entity scheme <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="entitySchemeId" placeholder='Optional' className='form-control w-100' value='CRN' onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Is Person
              </p>
            </div>
            <div className="flex-2-3">
              <CustomizedSelect
                elementName='isPerson'
                items={isPersonOptions}
                value={selectedIsPerson}
                handleChange={handleIsPersonChange}
                placeholder="Select a country..."
              />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Currency
              </p>
            </div>
            <div className="flex-2-3">
              <CustomizedSelect
                elementName='currency'
                items={currencyOptions}
                value={selectedCurrency}
                handleChange={handleCurrencyChange}
                placeholder="Select a country..."
              />
            </div>
          </div>
        </div>
        <div className="light-blue_bg highlighted-section m-t-1">
          Address <span className='dark-gray_font'>Optional</span>
        </div>
        <div className="client-form-container">
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Country iso code
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="countryIsoCode" className='form-control w-100' value={'SAU'} onChange={handleClientAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                City
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="city" className='form-control w-100' placeholder='Optional' value={clientAddress ? clientAddress?.city : ''} onChange={handleClientAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Street address <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="street_address" className='form-control w-100' value={clientAddress ? clientAddress?.streetAddress : ''} onChange={handleClientAddressInputChange} required />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Building number
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="building_number" className='form-control w-100' placeholder='Optional' value={clientAddress ? clientAddress?.buildingNumber : ''} onChange={handleClientAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                District
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="district" className='form-control w-100' placeholder='Optional' value={clientAddress ? clientAddress?.district : ''} onChange={handleClientAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Additional number
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="additional_number" className='form-control w-100' placeholder='Optional' value={clientAddress ? clientAddress?.additionalNumber : ''} onChange={handleClientAddressInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Postal code
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="postal_code" className='form-control w-100' placeholder='Optional' value={clientAddress ? clientAddress?.postalCode : ''} onChange={handleClientAddressInputChange} />
            </div>
          </div>
        </div>
        <div className="light-blue_bg highlighted-section m-t-1">
          Invoice Info <span className='dark-gray_font'>Optional</span>
        </div>
        <div className="client-form-container pb-[6rem]">
          <div className="flex-container">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Code <span className="required">*</span>
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="code" required className='form-control w-100' value={client ? client.code : ''} onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Email
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="email" placeholder='Optional' className='form-control w-100' value={client ? client.email : ''} onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                CC Email
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="ccEmail" placeholder='Optional' className='form-control w-100' value={client ? client.ccEmail : ''} onChange={handleClientInputChange} />
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Phone
              </p>
            </div>
            <div className="flex-2-3">
              <input type="text" name="phone" placeholder='Optional' className='form-control w-100' value={client ? client.phone : ''} onChange={handleClientInputChange} />
            </div>
          </div>
          {/* <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Relationship
              </p>
            </div>
            <div className="flex-2-3">
              <select name="country_id" id="country_id" className='form-control w-100 custom-expand-select'>
                <option value="">Select</option>
              </select>
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Payment terms
              </p>
            </div>
            <div className="flex-2-3">
              <select name="country_id" id="country_id" className='form-control w-100 custom-expand-select'>
                <option value="">Due on receipt</option>
              </select>
            </div>
          </div>
          <div className="flex-container m-t-1">
            <div className="flex-1-3 flex-container flex-align-center">
              <p>
                Additional ID
              </p>
            </div>
            <div className="flex-2-3">
              <select name="country_id" id="country_id" className='additional-id-inputs ml-1 form-select custom-expand-select'>
                <option value="">Select</option>
              </select>
              <input type="text" name="additional_id" placeholder="Additional ID" className='form-control additional-id-inputs' />
            </div>
          </div> */}
        </div>
        <input type="submit" value="Save" className='create-button clickable ' />
      </form>
    </div>
  )
}

export default ClientPopup