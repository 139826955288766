import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import Alert from '../shapes/Alert';
import '../../css/invoices.css'
import '../../css/tabs.css'
import '../../css/itinerary.css';
import { addCommaAndSeparator } from '../../utilities/helper';
import PDF from '../../assets/PDF.svg'
// import Expand from './../../assets/Expand.svg'
import Expand from './../../assets/Expand4.svg'
import Note from './../../assets/Note.svg'
import InvoiceView from './InvoiceView';
import InvoiceExpandPopup from './InvoiceExpandPopup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import AttachmentIcon from '../shapes/AttachmentIcon';
import { clear } from 'node:console';
import { current } from '@reduxjs/toolkit';
import CreateOptions from '../shapes/CreateOptions';
import { ExpandMore, OpenInFull } from '@mui/icons-material';
import { upperCase } from 'lodash';
interface Invoice {
  id: number;
  discount_amount: number;
  client_name: string;
  invoice_number: string;
  sale_type: string;
  issue_date: string;
  due_date: string;
  note: string;
  uni_code: string;
  profile_image: string;
  user_name: string;
  pdf_path: string;
  invoiceQ_reference_number: string;
}

const Invoices = () => {
  const param = useParams<{ uniCode: string }>();
  const uniCode = param.uniCode;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [totalTaxableAmount, setTotalTatotalTaxableAmount] = useState();
  const [totalVAT, setTotalVat] = useState();
  const [TAV, setTAV] = useState();
  const [discount, setDiscount] = useState();
  const [showInvoiceExpandPopup, setShowInvoiceExpandPopup] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuVisibleRowId, setMenuVisibleRowId] = useState<number | null>(null); // Track the row ID for the context menu

  const options = [
    { link: `/create-invoice/vat/${uniCode}`, label: 'VAT INVOICE' },
    { link: `/create-invoice/proforma/${uniCode}`, label: 'PROFORMA INVOICE' },
  ];

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/invoices/${uniCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      setInvoices(response.data.invoices)
      setTotalTatotalTaxableAmount(response.data.totalTaxableAmount)
      setTotalVat(response.data.totalVAT)
      setDiscount(response.data.discount)
      setTAV(response.data.TAV)
      console.log(response.data);
      setIsLoading(false)
    } catch (error) {
      console.log('Error fetching invoices:', error);
    }
  }
  useEffect(() => {
    if (counter < 1)
      fetchData();
    setCounter(counter + 1);
  }, []);

  const handleExpandPopup = () => {
    showInvoiceExpandPopup ? setShowInvoiceExpandPopup(false) : setShowInvoiceExpandPopup(true);
    console.log('expand');
  }

  const handleTrNavigate = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const closesTd = target.closest('td');
    if (closesTd && closesTd.classList.contains('prevent-navigation')) {
      return;
    }
    if (!e.currentTarget.classList.contains('upload-modal')) {
      const tr = target.closest('tr') as HTMLTableRowElement;
      console.clear();
      let invoiceType = '';
      const invoiceId = tr.getAttribute('data-id');
      if (invoiceId !== null) {
        const invoice = invoices.find(invoice => invoice.id === parseInt(invoiceId));
        invoiceType = invoice?.sale_type === 'PROFORMA INVOICE' ? 'proforma' : 'vat';
        console.log(invoiceType);
      }
      if (!target.classList.contains('prevent-navigation')) {
        console.log('/' + uniCode + '/invoice/' + invoiceType + '/' + tr.getAttribute('data-unicode'))
        navigate('/' + uniCode + '/invoice/' + invoiceType + '/' + tr.getAttribute('data-unicode'));
      }
    }
  }

  const downloadInvoice = async (id: number) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
      const response = await axios.get(`${url}/invoice/${id}/pdf`);
      const { pdf_url, file_name } = response.data;

      // Trigger download
      const link = document.createElement('a');
      link.href = pdf_url;
      link.download = file_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const handleDownload = (invoice) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
    console.clear();
    console.log('invoice:', invoice);
    const fileName = invoice.pdf_path.split('/').pop(); // Extract file name
    console.log(invoice.pdf_path);
    console.log(fileName);
    const downloadUrl = `${url}/download-invoice/${encodeURIComponent(fileName)}/${invoice.sale_type}`;
    // Trigger download
    window.open(downloadUrl, '_blank');
  };

  const showInvoiceTypes = () => {
    const createContainer = document.getElementsByClassName('create-container')[0] as HTMLElement;
    createContainer.classList.toggle('overnight');
  }

  const handleOptionClick = (action: string, uniCode: string, invoiceQReferenceNumber: string) => {
    if (action.toLowerCase() === 'credit') {
      console.clear();
      console.log(invoiceQReferenceNumber == '');
      console.log(invoiceQReferenceNumber == null);
      navigate(`/create-invoice/credit/${uniCode}${invoiceQReferenceNumber != null ? '?id=' + invoiceQReferenceNumber : ''}`);
    } else if (action.toLowerCase() === 'debit') {
      navigate(`/create-invoice/debit/${uniCode}${invoiceQReferenceNumber != null ? '?id=' + invoiceQReferenceNumber : ''}`);
    }
    setMenuVisible(false); // Hide the menu after the action
    setMenuVisibleRowId(null); // Reset the active row
  };

  const handleContextMenu = (e: React.MouseEvent, rowId: number) => {
    e.preventDefault(); // Prevent the default browser context menu
    setMenuVisible(true);
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setMenuVisibleRowId(rowId); // Track which row is active
  };

  useEffect(() => {
    setMenuVisible(false);
    return () => {
      setMenuVisible(false);
    };
  }, []);

  return (
    <>
      {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      <TableContainer id='invoices-table' component={Paper} style={{ height: '100vh', marginTop: '0px', overflowY: 'auto' }} className="table-container">
        <Table stickyHeader className='table-component'>
          <TableHead className='bg-grassy'>
            <TableRow className="table-header">
              <TableCell className='w-[25px]' padding="checkbox" style={{ width: '25px' }} />
              <TableCell className='w-[25px]' padding="none" />
              <TableCell className='w-[25px]' padding="none" />
              <TableCell className="" style={{ borderRight: '0.5px solid #eeefff' }}>CUSTOMER</TableCell>
              <TableCell className="align-center" style={{ borderRight: '0.5px solid #eeefff' }}>INVOICE </TableCell>
              <TableCell className="align-center" style={{ borderRight: '0.5px solid #eeefff' }}>INVOICE TYPE</TableCell>
              <TableCell className="align-center" style={{ borderRight: '0.5px solid #eeefff' }}>INVOICE DATE</TableCell>
              <TableCell className="align-center" style={{ borderRight: '0.5px solid #eeefff' }}>DUE DATE</TableCell>
              <TableCell className="align-center" style={{ borderRight: '0.5px solid #eeefff' }}>STATUS</TableCell>
              <TableCell className="align-center" style={{ borderRight: '0.5px solid #eeefff' }}>TTA</TableCell>
              <TableCell className="" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}>VAT</TableCell>
              <TableCell className="" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}>Discount</TableCell>
              <TableCell className="" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}>TAV</TableCell>
              <TableCell className="" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}></TableCell>
              <TableCell className="" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}></TableCell>
              <TableCell className="" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              invoices.map((invoice, i) => {
                return (
                  <TableRow key={invoice.id} className='pointer' data-unicode={invoice.uni_code} data-id={invoice.id} onClick={handleTrNavigate} >
                    <TableCell padding="checkbox" style={{ width: '25px' }}>
                      <Checkbox />
                    </TableCell>
                    <TableCell padding="none" className='prevent-navigation'>
                      <IconButton>
                        <ExpandMore />
                      </IconButton>
                    </TableCell>
                    <TableCell padding="none" className='prevent-navigation' onContextMenu={(e) => handleContextMenu(e, invoice.id)}>
                      <IconButton>
                        <OpenInFull
                          className='expand-icon pointer'
                          onContextMenu={(e) => handleContextMenu(e, invoice.id)} />
                        {menuVisible && menuVisibleRowId === invoice.id && upperCase(invoice.sale_type) == 'VAT INVOICE' && (
                          <ul className='context-menu prevent-navigation' style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, }}>
                            {
                              upperCase(invoice.sale_type) == 'VAT INVOICE' ?
                                <>
                                  <li className="border-b border-gray-200 hover:bg-gray-100  text-left" style={{ padding: '8px 12px', cursor: 'pointer' }}
                                    onClick={() => handleOptionClick('credit', uniCode || '', invoice.invoiceQ_reference_number)}
                                  >Credit</li>
                                  <li className="border-b border-gray-200 hover:bg-gray-100  text-left" style={{ padding: '8px 12px', cursor: 'pointer' }}
                                    onClick={() => handleOptionClick('debit', uniCode || '', invoice.invoiceQ_reference_number)}
                                  >Debit</li>
                                </>
                                : ''
                            }
                          </ul>
                        )}
                      </IconButton>
                    </TableCell>
                    {/* <TableCell className='expand-td half-cell'>
                      {menuVisible && menuVisibleRowId === invoice.id && (
                        <ul className='context-menu' style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, }}>
                          <li className="border-b border-gray-200 hover:bg-gray-100  text-left" style={{ padding: '8px 12px', cursor: 'pointer' }}
                            onClick={() => handleOptionClick('Delete', invoice.id)}
                          >Delete</li>
                          <li className="border-b border-gray-200 hover:bg-gray-100  text-left" style={{ padding: '8px 12px', cursor: 'pointer' }}
                            onClick={() => handleOptionClick('Delete', invoice.id)}
                          >Add Adjustment</li>
                        </ul>
                      )}
                    </TableCell> */}
                    <TableCell>{invoice.client_name}</TableCell>
                    <TableCell>{invoice.invoice_number}</TableCell>
                    <TableCell>{invoice.sale_type}</TableCell>
                    <TableCell>{invoice.issue_date}</TableCell>
                    <TableCell>{invoice.due_date}</TableCell>
                    <TableCell>DUE</TableCell>
                    <TableCell>{totalTaxableAmount ? addCommaAndSeparator(totalTaxableAmount[i]) : ''}</TableCell>
                    <TableCell>{totalVAT ? addCommaAndSeparator(totalVAT[i]) : ''}</TableCell>
                    <TableCell>{discount && discount[i] ? addCommaAndSeparator(discount[i]) : '0'}</TableCell>
                    <TableCell>{TAV ? addCommaAndSeparator(TAV[i]) : ''}</TableCell>
                    <TableCell className='prevent-navigation'>
                      <img src={Note} className='download-pdf note pointer clickable prevent-navigation' alt="note" />
                      {/* {invoice.note} */}
                    </TableCell>
                    <TableCell className='prevent-navigation upload-modal text-center'>
                      {/* <FontAwesomeIcon icon={faFileInvoice} style={{ color: 'red' }} /> */}
                      {/* <AttachmentIcon recordId={invoice.id} /> */}
                      <button onClick={() => handleDownload(invoice)}>
                        <FontAwesomeIcon icon={faFileInvoice} style={{ color: 'red' }} />
                      </button>

                    </TableCell>
                    <TableCell className='customer-circle-td icon-container min-h-[34px]'>
                      {
                        invoice.profile_image ?
                          <img src={process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + invoice.profile_image} alt="profile" className='customer-circle-image' />
                          :
                          <div className="unselectable customer-circle">
                            {invoice.user_name ? invoice.user_name.substring(0, 2).toUpperCase() : ''}
                          </div>
                      }
                      <span className="hover-label">{invoice.user_name}</span>
                      {/* <img src={PDF} alt="pdf-icon" className='download-pdf pointer' /> */}
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading ? <p className="bold-text" style={
        {
          'color': '#94ddf3',
          'display': 'inline-block',
          'marginLeft': '1rem',
          'fontSize': '2rem',
          // 'position': 'fixed',
          // 'top': '1rem',
        }}>
        Loading...
      </p> : ""}
      {/* <Link className='create-invoice create-button clickable' to={`/create-invoice/${uniCode}`}>Create Invoice</Link> */}

      <CreateOptions options={options} />
      <div className='unselectable create-invoice create-button clickable pointer' onClick={showInvoiceTypes}>Create Invoice</div>
      {/* <InvoiceExpandPopup showInvoiceExpandPopup={showInvoiceExpandPopup} /> */}
    </>
  )
}

export default React.memo(Invoices)