import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Alert from '../shapes/Alert';
import Expand from './../../assets/Expand4.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { Vendor } from '../../types/Vendor';
import { Link } from 'react-router-dom';
import '../../css/vendors.css'
import { faExpandArrowsAlt, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VendorPopup from './VendorPopup';
import { VendorAddress } from '../../types/VendorAddress';
import { debounce } from 'lodash';
import Country from '../shapes/Country';
import useRoleAccess from '../../hooks/useRoleAccess';


const validateEmail = (value: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};

const debouncedVendorPerField = debounce((vendorId: number, updatedField: string, value: string, token: string) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/update-vendor-per-field/${vendorId}`;
    const response = axios.post(
      url,
      { name: updatedField, value },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    // if (response)
    // console.log(response.data);
    // if (vendors) {
    //   const updatedItineraries = vendors.filter((itinerary) => itinerary.id !== rowId);
    //   setVendors(updatedItineraries); // This should trigger a re-render
    // }
  } catch (error) {
    console.error('Error deleting itinerary detail:', error);
  }
}, 500)


const Vendors = () => {
  const param = useParams<{ uniCode: string }>();
  const uniCode = param.uniCode;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuVisibleRowId, setMenuVisibleRowId] = useState<number | null>(null); // Track the row ID for the context menu

  const [vendor, setVendor] = useState<Vendor>({
    id: 0,
    name: '',
    arabicName: '',
    code: '',
    phone: '',
    email: '',
    vatTreatment: '',
    trn: '',
    iban: '',
    swift: '',
    profile_image: '',
    vendorAddress: {
      id: 0,
      city: '',
      district: '',
      streetAddress: '',
      buildingNumber: '',
      additionalNumber: '',
      postalCode: '',
      country: '',
    },
  });
  const [vendorAddress, setVendorAddress] = useState<VendorAddress | null>({
    id: 0,
    city: '',
    district: '',
    streetAddress: '',
    buildingNumber: '',
    additionalNumber: '',
    postalCode: '',
    country: '',
  });
  const [showInvoiceExpandPopup, setShowInvoiceExpandPopup] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const isWorkspaceOwner = useRoleAccess('Workspace Owner');
  const isAdministrator = useRoleAccess('Administrator');
  const isAccountant = useRoleAccess('Accountant');
  const isAccountantAndAdministrator = isWorkspaceOwner || isAdministrator || isAccountant

  const handleVendorsChange = (vendors) => {
    setVendors(vendors)
  }
  const fetchInitialData = async () => {


    if (!isAccountantAndAdministrator) navigate('/not-authorized')

    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-all-vendors/${uniCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      setVendors(response.data.vendors)
      console.clear();
      console.log(response.data);
      console.log(response.data.vendors);
      setIsLoading(false)
    } catch (error) {
      console.log('Error fetching invoices:', error);
    }
  }
  useEffect(() => {
    if (counter < 1)
      fetchInitialData();
    setCounter(counter + 1);
  }, []);

  const handleVendorInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name + " " + value);
    if (vendor) {
      const updatedName = name === 'vendor_name' ? 'name' : name; // Map input field name to vendor field
      // setVendor((prevVendor) => (prevVendor ? { ...prevVendor, [updatedName]: value } : null));
      console.log('name: ' + name + ' value: ' + value);
      setVendor((prev) => ({ ...prev, [updatedName]: value }));
    }
  };

  useEffect(() => {
    if (vendor) {
      console.log('useEffect Vendor:', vendor);
    }
  }, [vendor]);

  const handleVendorAddressInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (vendorAddress) {
      // Map input name from snake_case to camelCase
      const updatedName = name === 'street_address' ? 'streetAddress'
        : name === 'building_number' ? 'buildingNumber'
          : name === 'additional_number' ? 'additionalNumber'
            : name === 'postal_code' ? 'postalCode'
              : name; // Default to the input's name

      setVendorAddress((prevAddress) => (prevAddress ? { ...prevAddress, [updatedName]: value } : null));
    }
  };

  // const handleTableCountryChange = (newValue: string, vendorId: number | null) => {
  //   if (vendorId) {
  //     console.log(150)
  //     setVendors((prevVendors) =>
  //       prevVendors.map((vendor) =>
  //         vendor.id === vendorId
  //           ? {
  //             ...vendor,
  //             vendorAddress: {
  //               ...vendor.vendorAddress,
  //               country: newValue,
  //             },
  //           }
  //           : vendor
  //       )
  //     );
  //     try {
  //       const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/update-vendor-per-field/${vendorId}`;
  //       const response = axios.post(
  //         url,
  //         { vendorId, name: 'country', value: newValue },
  //         { headers: { Authorization: `Bearer ${token}` } }
  //       );
  //       // if (response)
  //       // console.log(response.data);
  //       // if (vendors) {
  //       //   const updatedItineraries = vendors.filter((itinerary) => itinerary.id !== rowId);
  //       //   setVendors(updatedItineraries); // This should trigger a re-render
  //       // }
  //     } catch (error) {
  //       console.error('Error deleting itinerary detail:', error);
  //     }
  //   }
  // }

  const handleCountryChange = (newValue: string) => {
    console.log("OLD handleCountryChange")
    setVendorAddress((prevAddress) =>
      prevAddress ? { ...prevAddress, country: newValue } : null
    )


    setVendor((prevVendor) =>
      prevVendor && prevVendor.id === 1
        ? {
          ...prevVendor,
          vendorAddress: {
            ...prevVendor.vendorAddress,
            country: newValue,
          },
        }
        : prevVendor
    );

  };
  useEffect(() => {
    console.log(vendorAddress);
    console.log(vendors);
  }, [vendorAddress, vendors]);

  const handleCopyIban = (e: React.MouseEvent) => {
    const target = e.currentTarget as HTMLTableCellElement
    const content = target.querySelector('input') as HTMLInputElement;
    navigator.clipboard.writeText(content.value)
      .then(() => {
        // Add blue background
        content.style.backgroundColor = 'lightblue';
        // Revert the background after 3 seconds
        setTimeout(() => {
          content.style.backgroundColor = ''; // Reset to default
        }, 500);

      })
  }

  const handleShowVendorPopup = (e: React.MouseEvent<HTMLElement>) => {
    const currentTarget = e.currentTarget;
    const target = e.target as HTMLElement;
    if (target.classList.contains('prevent-popup')) {
      return;
    }
    // empty vendor state
    setVendor(
      {
        id: 0,
        name: '',
        arabicName: '',
        code: '',
        phone: '',
        email: '',
        vatTreatment: '',
        trn: '',
        iban: '',
        swift: '',
        profile_image: '',
        vendorAddress: {
          id: 0,
          city: '',
          district: '',
          streetAddress: '',
          buildingNumber: '',
          additionalNumber: '',
          postalCode: '',
          country: '',
        },
      }
    );
    setVendorAddress(
      {
        id: 0,
        city: '',
        district: '',
        streetAddress: '',
        buildingNumber: '',
        additionalNumber: '',
        postalCode: '',
        country: '',
      }
    );
    // if (currentTarget.tagName === 'TD') {
    const tr = currentTarget.closest('tr')
    fetchVendorData(tr?.getAttribute('data-id') || '');
    const unicode = tr?.getAttribute('data-unicode');
    console.log('unicode:', unicode);
    // }
    const vendorPopup = document.getElementById('vendor-popup') as HTMLElement;
    if (vendorPopup) {
      vendorPopup.classList.add('show'); // Add the 'show' class to slide in the popup
    }
  }

  const fetchVendorData = async (id: string | null) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-vendor/${id}`;
    try {
      setIsLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });

      const vendor = response.data.vendor;
      const vendorAddress = response.data.vendorAddress;

      setVendor(vendor);
      setVendorAddress(vendorAddress);

      console.log('Vendor data:', vendor);
      console.log('vendor address:', vendorAddress);
    } catch (error) {
      console.log('Error in fetching vendor data:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleContextMenu = (e: React.MouseEvent, rowId: number) => {
    e.preventDefault(); // Prevent the default browser context menu
    setMenuVisible(true);
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setMenuVisibleRowId(rowId); // Track which row is active
  };

  const handleOptionClick = (action: string, rowId: number) => {
    if (action.toLowerCase() === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to delete this vendor?');
      if (confirmDelete) {
        deleteVendor(rowId);
      }
    } else if (action.toLowerCase() === 'expand') {
      navigate(`/vendor/${rowId}`);
    }
    setMenuVisible(false); // Hide the menu after the action
    setMenuVisibleRowId(null); // Reset the active row
  };

  const deleteVendor = async (rowId: number) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/delete-vendor/${rowId}`;
      const response = await axios.post(
        url,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (vendors) {
        const updatedItineraries = vendors.filter((itinerary) => itinerary.id !== rowId);
        setVendors(updatedItineraries); // This should trigger a re-render
      }
    } catch (error) {
      console.error('Error deleting itinerary detail:', error);
    }
  };

  const handleTableUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const row = e.target.closest('tr') as HTMLTableRowElement;
    const vendorId = Number(row.getAttribute('data-id'));
    let validEmail = false;
    if (!vendorId) return;

    const updatedField = name === 'vendor_name' ? 'name' : name; // Ensure correct field name mapping

    setVendors((prevVendors) =>
      prevVendors.map((vendor) =>
        vendor.id === vendorId ? { ...vendor, [updatedField]: value } : vendor
      )
    );

    if (updatedField === 'email') {
      validEmail = validateEmail(value);
      if (validEmail) {
        setAlertBody('email has valid structure');
        setAlertStatus('success');
        setShowAlert(true);
      } else {
        setAlertBody('email has invalid structure and it will not be added to the system');
        setAlertStatus('error');
        setShowAlert(true);
      }
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);

    if ((updatedField === 'email' && validEmail) || updatedField !== 'email') {
      debouncedVendorPerField(vendorId, updatedField, value, token || '')
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const { name, value } = target;
    const row = target.closest('tr') as HTMLTableRowElement;
    const vendorId = Number(row.getAttribute('data-id'));
    setVendors((prevVendors) =>
      prevVendors.map((vendor) =>
        vendor.id === vendorId ? { ...vendor, [name]: value } : vendor
      )
    );
  }

  // const updateVendorPerField = debounce((vendorId: number, updatedField: string, value: string) => {
  //   try {
  //     const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/update-vendor-per-field/${vendorId}`;
  //     const response = axios.post(
  //       url,
  //       { name: updatedField, value },
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     );

  //     // if (response)
  //     // console.log(response.data);
  //     // if (vendors) {
  //     //   const updatedItineraries = vendors.filter((itinerary) => itinerary.id !== rowId);
  //     //   setVendors(updatedItineraries); // This should trigger a re-render
  //     // }
  //   } catch (error) {
  //     console.error('Error deleting itinerary detail:', error);
  //   }
  // }, 500)

  return (
    <>
      {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      {/* <div className="navigate-tabs flex-container flex-start">
        <div className="tab clickable pointer active">INFO SHEET</div>
        <div className="tab clickable pointer">INVOICE SHEET</div>
        <div className="tab clickable pointer">ACCOUNT STATEMENT</div>
      </div> */}
      {/* <div className='title-container'>
        <h2><b>Contacts</b></h2>
      </div> */}
      <table id='vendors-table' className='w-full'>
        <thead className='bg-grassy'>
          <tr>
            <th className='-cell w-[4rem]'></th>
            {/* <th className='-cell pl-6'>name</th> */}
            <th className='-cell'>full name</th>
            <th className='-cell'>arabic name</th>
            {/* <th className='-cell'>email</th> */}
            {/* <th className='-cell'>tax registration</th> */}
            {/* <th className='-cell w-[25rem]'>country</th> */}
            <th className='-cell'>iban</th>
            <th className='-cell'>Account</th>
            <th className='-cell'></th>
            <th className='w-2'></th>
          </tr>
        </thead>
        <tbody>
          {
            vendors.map((vendor, i) => {
              return (
                <tr key={vendor.id} className='vendor-tr pointer' data-unicode={1} data-id={vendor.id}>
                  <td className="text-center" onClick={handleShowVendorPopup}>
                    <FontAwesomeIcon
                      icon={faExpandArrowsAlt}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation(); // Prevent event bubbling
                        handleShowVendorPopup(e as React.MouseEvent<HTMLElement>); // Cast to match expected type
                      }}
                      onContextMenu={(e: React.MouseEvent<SVGSVGElement>) => handleContextMenu(e, vendor.id)}
                    />
                    {menuVisible && menuVisibleRowId === vendor.id && (
                      <ul className='context-menu' style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, }}>
                        <li className="border-b border-gray-200 hover:bg-gray-100  text-left" style={{ padding: '8px 12px', cursor: 'pointer' }}
                          onClick={() => handleOptionClick('Delete', vendor.id)}
                        >Delete</li>
                        <li className="border-b border-gray-200 hover:bg-gray-100  text-left" style={{ padding: '8px 12px', cursor: 'pointer' }}
                          onClick={() => handleOptionClick('Expand', vendor.id)}
                        >Expand</li>
                      </ul>
                    )}
                  </td>
                  {/* <td className="code-td pl-6">{vendor.code || '--'}</td> */}
                  <td>
                    <input
                      type="text"
                      name="vendor_name" // Ensure this matches the condition in handleTableUpdate
                      id="name"
                      className="w-100 bg-transparent uppercase"
                      value={vendor.name}
                      onChange={handleTableUpdate}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="arabic_name" // Ensure this matches the condition in handleTableUpdate
                      id="name"
                      className="w-100 bg-transparent"
                      value={vendor.arabicName}
                      onChange={handleTableUpdate}
                    />
                  </td>
                  {/* <td>
                    <input type="text" name="email" id="email" className="w-100 bg-transparent" value={vendor.email || '--'} onChange={handleInputChange} onBlur={handleTableUpdate} />
                  </td> */}
                  {/* <td className="tax-registration-td">{vendor.trn || '--'}</td> */}
                  {/* <td className="country-id-td">
                    <Country
                      id={vendor.id}
                      country={vendor.vendorAddress.country}
                      handleCountryChange={handleTableCountryChange}
                      withRequest={true}
                    />
                  </td> */}
                  <td className="iban-td prevent-popup" onClick={handleCopyIban}>
                    <p className='inline'>
                      <input type="text" name="iban" id="iban" className="w-100 bg-transparent" value={vendor.iban || '-'} onChange={handleTableUpdate} />
                    </p>
                  </td>
                  <td className="swift-td">{'-'}</td>
                  <td className="country-id-td align-center">
                    <FontAwesomeIcon icon={faPaperclip} size="2x" color="#000" />
                  </td>
                  <td className="poc">
                    {
                      vendor.profile_image ?
                        <img src={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${vendor.profile_image}`} alt="Profile" className='customer-circle-image' />
                        :
                        <div className="unselectable customer-circle">
                          {vendor.name ? vendor.name.substring(0, 2).toUpperCase() : ''}
                        </div>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {isLoading ? <p className="bold-text" style={
        {
          'color': '#94ddf3',
          'display': 'inline-block',
          'marginLeft': '1rem',
          'fontSize': '2rem',
        }}>
        Loading...
      </p> : ""}
      {/* <Link className='create-vendor create-button clickable' to={`/create-invoice/${uniCode}`}>Add Vendor</Link> */}
      <div className="add-vendor create-button clickable pointer" onClick={handleShowVendorPopup}>Add Supplier</div>
      {/* <InvoiceExpandPopup showInvoiceExpandPopup={showInvoiceExpandPopup} /> */}
      <VendorPopup
        vendor={vendor}
        vendorAddress={vendorAddress}
        handleVendorInputChange={handleVendorInputChange}
        handleVendorAddressInputChange={handleVendorAddressInputChange}
        handleCountryChange={handleCountryChange}
        handleVendorsChange={handleVendorsChange}
      />
    </>
  )
}

export default React.memo(Vendors)