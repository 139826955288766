import React, { useEffect, useState } from 'react'
import Dashboard from '../../assets/Dashboard.svg';
import BankBuilding from '../../assets/BankBuilding.svg';
import ComboChart from '../../assets/ComboChart.svg';
import Menu from '../../assets/Menu.svg';
import Airtable from '../../assets/Airtable.svg';
import InvoiceNav from '../../assets/InvoiceNav.png';
import AI from '../../assets/AI.svg';
import { Link, useLocation, useParams } from 'react-router-dom';
import Logout from '../auth/Logout';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Workspace } from '../../types/Workspace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxesPacking as faBoxesPackingRegular,
  faBuildingColumns as faBuildingColumnsRegular,
  faChartSimple as faChartSimpleRegular,
  faDatabase as faDatabaseRegular,
  faFile as faFileRegular,
  faGearCode as faGearCodeRegular,
  faGrid2 as faGrid2Regular,
  faWebhook as faWebhookRegular,
  faHouse as faHouseRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBoxesPacking as faBoxesPackingThin,
  faBuildingColumns as faBuildingColumnsThin,
  faChartSimple as faChartSimpleThin,
  faDatabase as faDatabaseThin,
  faFile as faFileThin,
  faGearCode as faGearCodeThin,
  faGrid2 as faGrid2Thin,
  faWebhook as faWebhookThin,
  faHouse as faHouseThin,

} from '@fortawesome/pro-thin-svg-icons';
import useWorkspaceAccess from '../../hooks/useWorkspaceAccess';
import useRoleAccess from '../../hooks/useRoleAccess';

interface props {
  selectedWorkspace: Workspace;
}

const Navbar = ({ selectedWorkspace }) => {
  const [pageUrl, setPageUrl] = useState('');
  const user = useSelector((state: RootState) => state.userState)
  const { id, uniCode } = useParams();
  // Get the current location object
  const location = useLocation();

  const isWorkspaceOwner = useRoleAccess('Workspace Owner');
  const isAdministrator = useRoleAccess('Administrator');
  const isAccountant = useRoleAccess('Accountant');

  const isAccountantAndAdministrator = isWorkspaceOwner || isAdministrator || isAccountant
  useEffect(() => {
    // Extract the pathname (e.g., "/invoices/R6AmEkD4H")
    const pathname = location.pathname;
    // Split the pathname into segments
    const segments = pathname.split('/'); // Output: ["", "invoices", "R6AmEkD4H"]
    setPageUrl(segments[1])
  }, [location.pathname]);


  const handleShowMenu = () => {
    const menu = document.getElementById('menu')
    const logout = document.getElementById('logout')

    if (menu?.style.left === '0px' && logout?.style.left === '0px') {
      menu.style.left = '-16.7%';
      logout.style.left = '-16.7%';
    } else {
      if (menu) menu.style.left = '0px';
      if (logout) logout.style.left = '0px';
    }
  }

  const showNavbarTitle = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.currentTarget;
    const title = target.nextElementSibling
    if (title) title.classList.toggle('overnight');
  }

  const hideNavbarTitle = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.currentTarget;
    const title = target.nextElementSibling
    if (title) title.classList.toggle('overnight');
  }

  return (
    <>
      <div id='navbar-side' className='vertical-flex-container z-[1000]'>
        <img src={Menu} alt="Menu" id='menu-icon' onClick={handleShowMenu} />
        <div className="relative w-100">
          <Link to={`/`} className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
            {/* <span className={pageUrl === 'dashboard' ? `active-indicator` : ''}></span> */}
            <FontAwesomeIcon icon={faHouseThin} className={`${pageUrl === 'dashboard' ? `navbar-icon-active` : ''} navbar-icon`} />
          </Link>
          <span className='navbar-icon-title overnight'>Home</span>
        </div>
        <div className="relative w-100">
          <Link to={`/dashboard/${selectedWorkspace.uniCode}`} className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
            <span className={pageUrl === 'dashboard' ? `active-indicator` : ''}></span>
            <FontAwesomeIcon icon={pageUrl === 'dashboard' ? faGrid2Regular : faGrid2Thin} className={`${pageUrl === 'dashboard' ? `navbar-icon-active` : ''} navbar-icon`} />
          </Link>
          <span className='navbar-icon-title overnight'>Dashboard</span>
        </div>
        <div className="relative w-100">
          <Link to={`/banks/${selectedWorkspace.uniCode}`} className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
            <span className={pageUrl === 'banks' ? `active-indicator` : ''}></span>
            <FontAwesomeIcon icon={pageUrl === 'banks' ? faBuildingColumnsRegular : faBuildingColumnsThin} className={`${pageUrl === 'banks' ? `navbar-icon-active` : ''} navbar-icon`} />
          </Link>
          <span className='navbar-icon-title overnight'>CASH MANAGEMENT</span>
        </div>
        <div className="relative w-100">
          <Link to="/reports" className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
            <span className={pageUrl === 'reports' ? `active-indicator` : ''}></span>
            <FontAwesomeIcon icon={pageUrl === 'reports' ? faChartSimpleRegular : faChartSimpleThin} className={`${pageUrl === 'reports' ? `navbar-icon-active` : ''} navbar-icon`} />
          </Link>
          <span className='navbar-icon-title overnight'>FINANCIAL DATA</span>
        </div>
        <div className="relative w-100">
          <Link to="/bases" className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
            <span className={pageUrl === 'bases' ? `active-indicator` : ''}></span>
            <FontAwesomeIcon icon={pageUrl === 'bases' ? faWebhookRegular : faWebhookThin} className={`navbar-icon`} />
          </Link>
          <span className='navbar-icon-title overnight'>WEBHOOK</span>
        </div>
        <div className="relative w-100">
          <Link to={`/invoices/${selectedWorkspace.uniCode}`} className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
            <span className={pageUrl === 'invoices' ? `active-indicator` : ''}></span>
            <FontAwesomeIcon icon={pageUrl === 'invoices' ? faFileRegular : faFileThin} className={`${pageUrl === 'invoices' ? `navbar-icon-active` : ''} navbar-icon`} />
          </Link>
          <span className='navbar-icon-title overnight'>INVOICING SYSTEM</span>
        </div>
        <div className="relative w-100">
          <Link to={`/clients/${selectedWorkspace.uniCode}`} className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
            <span className={pageUrl === 'clients' ? `active-indicator` : ''}></span>
            <FontAwesomeIcon icon={pageUrl === 'clients' ? faDatabaseRegular : faDatabaseThin} className={`${pageUrl === 'clients' ? `navbar-icon-active` : ''} navbar-icon`} />
          </Link>
          <span className='navbar-icon-title overnight'>CLIENTS DATABASE- {isAdministrator}  . </span>
        </div>
        {
          isAccountantAndAdministrator ?
            <div className="relative w-100">
              <Link to={`/vendors/${selectedWorkspace.uniCode}`} className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
                <span className={pageUrl === 'vendors' ? `active-indicator` : ''}></span>
                <FontAwesomeIcon icon={pageUrl === 'vendors' ? faBoxesPackingRegular : faBoxesPackingThin} className={`${pageUrl === 'vendors' ? `navbar-icon-active` : ''} navbar-icon`} />
              </Link>
              <span className='navbar-icon-title overnight'>SUPPLIERS DATABASE</span>
            </div>
            : ''
        }
        {useWorkspaceAccess('R6AmEkD4H') ?
          <div className="relative w-100">
            <Link to={`/ttp-sales/R6AmEkD4H/all`} className='tooltip-container' onMouseEnter={showNavbarTitle} onMouseLeave={hideNavbarTitle}>
              <span className={pageUrl === 'ttp' ? `active-indicator` : ''}></span>
              <FontAwesomeIcon icon={pageUrl === 'ttp' ? faGearCodeRegular : faGearCodeThin} className={`${pageUrl === 'ttp' ? `navbar-icon-active` : ''} navbar-icon`} />
            </Link>
            <span className='navbar-icon-title overnight'>CUSTOM SOLUTIONS</span>
          </div>
          :
          ''
        }
        <div className="vertical-flex-container menu-width">
          <img src={AI} alt="AI" className='nav-item bottom-position' />
        </div>
      </div>
    </>
  )
}

export default Navbar;