import { useNavigate } from "react-router-dom";
// import { ShieldAlert } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShield } from "@fortawesome/pro-regular-svg-icons";
import SEJELLogo from '../../assets/png_trns_logo_white 1.svg';
const NotAuthorized = () => {
    const navigate = useNavigate();

    return (
        <div className="flex items-center justify-start min-h-screen bg-gray-100 login-page ml-0">

            <div className="flex-container w-100">
                <div className="flex-2-2 left-side">
                    <div className="bg-white p-8 rounded-2xl shadow-lg max-w-lg text-center h-[100vh]">
                        {/* <ShieldAlert className="w-16 h-16 text-red-500 mx-auto" /> */}
                        <FontAwesomeIcon icon={faShield} className="w-16 h-16 text-red-500 mx-auto" />
                        <h1 className="text-2xl font-bold text-gray-800 mt-4">Access Denied</h1>
                        <p className="text-gray-600 mt-2">
                            You don’t have permission to view this page. Please contact your administrator if you believe this is an error.
                        </p>
                        {/* <button
                            className="mt-6 px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
                            onClick={() => navigate(-1)}
                        > 
                            Go Back
                        </button> */}
                    </div>
                    <div className="flex-2-2">
                        <img src={SEJELLogo} alt="logo" className="fixed right-0 top-0" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotAuthorized;
