import React, { useEffect, useRef, useState } from 'react';
import {
    Drawer,
    Typography,
    Divider,
    Box,
    Button,
    IconButton,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputBase
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import { Transaction, SubTransaction } from '../interfaces';

import axios from 'axios';

// Example interface
// interface Transaction {
//     id: string;
//     amount: number;
//     value_date_time: string;
//     transaction_information?: string;
//     currency?: string;
//     credit_debit_indicator?: string;
//     // ...other fields
// }

// interface SubTransaction {
//     description: string;
//     valueDate: string;
//     amount: number;
//     rawAmount: string; // We store user’s raw string for partial formatting
// }

interface TransactionBreakdownDrawerProps {
    open: boolean;
    transaction: Transaction | null;
    onClose: () => void;
    onConfirm: (subTransactions: SubTransaction[]) => void;
}

const token = localStorage.getItem('token');

/**
 * Utility to insert commas in the integer part only.
 * Leaves decimal portion as is.
 */
function insertCommasIntoIntegerPart(value: string): string {
    const hasDecimal = value.includes('.');
    let integerPart = hasDecimal ? value.split('.')[0] : value;
    const decimalPart = hasDecimal ? value.split('.')[1] : '';

    // Preserve the sign if the number is negative
    const sign = integerPart.startsWith('-') ? '-' : '';

    // Remove any non-digit characters (commas, etc.) from the integer part, except the minus sign for negative numbers
    integerPart = integerPart.replace(/[^\d]/g, '');

    // Insert commas in integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Re-add the negative sign if it was removed
    integerPart = sign + integerPart;

    return hasDecimal ? `${integerPart}.${decimalPart}` : integerPart;
}

/**
 * A bare input for amounts, no borders, that handles comma-separation.
 * Preserves cursor position after formatting.
 */
const FormattedNumberInput: React.FC<{
    value: number;
    rawValue: string;
    onChange: (rawValue: string, parsedValue: number) => void;
}> = ({ value, rawValue, onChange }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target;
        const startPos = input.selectionStart ?? input.value.length;

        // Remove commas to get raw numeric string
        const noCommas = input.value.replace(/,/g, '');
        let parsed = parseFloat(noCommas);
        if (isNaN(parsed)) parsed = 0;

        // Re-insert commas in the integer part only
        const formatted = insertCommasIntoIntegerPart(noCommas);

        // Update parent state
        onChange(formatted, parsed);

        // Reposition cursor
        requestAnimationFrame(() => {
            if (!inputRef.current) return;

            const oldLength = rawValue.length;
            const newLength = formatted.length;
            const diff = newLength - oldLength;

            let newPos = startPos + diff;
            if (newPos < 0) newPos = 0;
            if (newPos > formatted.length) newPos = formatted.length;

            inputRef.current.setSelectionRange(newPos, newPos);
        });
    };

    return (
        <InputBase
            inputRef={inputRef}
            value={rawValue}
            onChange={handleChange}
            fullWidth
            sx={{
                padding: 0,
                '& .MuiInputBase-input': {
                    borderBottom: '1px solid transparent',
                    fontSize: 'inherit'
                }
            }}
        />
    );
};

/**
 * A bare input for text/date, no borders.
 */
const BareInput: React.FC<{
    value: string;
    onChange: (value: string) => void;
    type?: string;
}> = ({ value, onChange, type }) => (
    <InputBase
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type}
        fullWidth
        sx={{
            padding: 0,
            '& .MuiInputBase-input': {
                borderBottom: '1px solid transparent',
                fontSize: 'inherit'
            }
        }}
    />
);

const TransactionBreakdownDrawer: React.FC<TransactionBreakdownDrawerProps> = ({
    open,
    transaction,
    onClose,
    onConfirm
}) => {
    const [subTransactions, setSubTransactions] = useState<SubTransaction[]>([]);
    useEffect(() => {
        if (transaction) {
            setSubTransactions(transaction.sub_transactions ?? []);
        }
    }, [transaction]);
    const [isPreview, setIsPreview] = useState<boolean>(false);

    if (!transaction) {
        return (
            <Drawer
                anchor="right"
                open={open}
                onClose={onClose}
                PaperProps={{ style: { width: '60vw', padding: '16px' } }}
            >
                <Typography variant="h6">No transaction selected</Typography>
            </Drawer>
        );
    }

    console.log(transaction);
    console.log('subTransactions: ');
    console.log(subTransactions);

    // Convert to number if stored as string
    const originalAmount = Number(transaction.amount) || 0;
    const totalSubAmount = subTransactions.reduce((sum, row) => sum + Number(row.amount), 0);
    const amountsMatch = Math.abs(totalSubAmount - originalAmount) < 0.000001;
    const remainingAmount = originalAmount - totalSubAmount;

    const handleAddSubTransaction = () => {
        setSubTransactions((prev) => [
            ...prev,
            {
                description: '',
                value_date: '',
                amount: 0,
                rawAmount: '0'
            }
        ]);
    };

    const handleDeleteSubTransaction = (index: number) => {
        setSubTransactions((prev) => prev.filter((_, i) => i !== index));
    };

    const handleDescriptionChange = (index: number, value: string) => {
        setSubTransactions((prev) => {
            const updated = [...prev];
            updated[index].description = value;
            return updated;
        });
    };

    const handleValueDateChange = (index: number, value: string) => {
        setSubTransactions((prev) => {
            const updated = [...prev];
            updated[index].value_date = value;
            return updated;
        });
    };

    const handleAmountChange = (index: number, rawValue: string, parsedValue: number) => {
        setSubTransactions((prev) => {
            const updated = [...prev];
            updated[index].rawAmount = rawValue;
            updated[index].amount = parsedValue;
            return updated;
        });
    };

    const validateRequiredFields = (): boolean => {
        for (let i = 0; i < subTransactions.length; i++) {
            const st = subTransactions[i];
            // require description and value_date
            if (!st.description.trim() || !st.value_date.trim()) {
                return false;
            }
        }
        return true;
    };

    /**
     * Proceed to preview if validation passes
     */
    const handleNext = () => {
        // Validate required fields
        if (!validateRequiredFields()) {
            alert('Description and Value Date are required for all sub-transactions.');
            return;
        }
        // Validate amounts
        if (!amountsMatch) {
            alert('The total of sub-transactions must match the original amount.');
            return;
        }
        setIsPreview(true);
    };

    const handleBack = () => {
        setIsPreview(false);
    };

    /**
     * When user clicks Confirm, send sub-transactions to the backend
     */
    const handleConfirmClick = async () => {
        try {
            // Example endpoint: POST /transactions/{id}/sub-transactions
            // Adjust based on your actual routes
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/transactions/${transaction.id}/sub-transactions`,
                {
                    subTransactions: subTransactions.map((st) => ({
                        description: st.description,
                        valueDate: st.value_date,
                        amount: st.amount
                    }))
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log('Sub-transactions created successfully:', response.data);

            // On success, call parent callback
            onConfirm(response.data.transaction.sub_transactions);

            handleCloseDrawer();
        } catch (error) {
            console.error('Error creating sub-transactions:', error);
            alert('Failed to create sub-transactions on the server.');
        }
    };

    const handleCloseDrawer = () => {
        setIsPreview(false);
        setSubTransactions([]);
        onClose();
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleCloseDrawer}
            PaperProps={{ style: { width: '60vw', padding: '16px' } }}
        >
            {/* EDIT MODE */}
            {!isPreview && (
                <>
                    <Typography variant="h6" gutterBottom>
                        Break down Transaction #{transaction.id}
                    </Typography>
                    <Divider style={{ margin: '16px 0' }} />

                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Value Date</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell width="50px">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Original transaction row (read-only) */}
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2">
                                        {transaction.transaction_information?.length > 70
                                            ? transaction.transaction_information.slice(0, 70) + '...'
                                            : transaction.transaction_information}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {new Date(transaction.value_date_time).toLocaleDateString()}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {originalAmount.toLocaleString('en-US')}
                                    </Typography>
                                </TableCell>
                                <TableCell />
                            </TableRow>

                            {/* Sub-transaction rows (editable) */}
                            {subTransactions.map((sub, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <BareInput
                                            value={sub.description}
                                            onChange={(val) => handleDescriptionChange(index, val)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <BareInput
                                            type="date"
                                            value={sub.value_date}
                                            onChange={(val) => handleValueDateChange(index, val)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ minWidth: '80px' }}>
                                        <FormattedNumberInput
                                            value={sub.amount}
                                            rawValue={sub.rawAmount ?? 0}
                                            onChange={(rawVal, parsedVal) =>
                                                handleAmountChange(index, rawVal, parsedVal)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteSubTransaction(index)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Box marginTop={2}>
                        <Button
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={handleAddSubTransaction}
                            variant="outlined"
                            style={{ marginBottom: '16px' }}
                        >
                            Add Row
                        </Button>
                    </Box>

                    <Divider style={{ margin: '16px 0' }} />

                    <Typography variant="body1">
                        Original Amount: {originalAmount.toLocaleString('en-US')}
                    </Typography>
                    <Typography variant="body1">
                        Sub-Total: {totalSubAmount.toLocaleString('en-US')}
                    </Typography>
                    <Typography variant="body1" color={amountsMatch ? 'inherit' : 'error'}>
                        Remaining: {remainingAmount.toLocaleString('en-US')}
                    </Typography>

                    <Box marginTop={2} display="flex" justifyContent="space-between">
                        <Button onClick={handleCloseDrawer} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                            disabled={subTransactions.length === 0}
                        >
                            Next
                        </Button>
                    </Box>
                </>
            )}

            {/* PREVIEW MODE */}
            {isPreview && (
                <>
                    <Typography variant="h6" gutterBottom>
                        Preview Breakdown for Transaction #{transaction.id}
                    </Typography>
                    <Divider style={{ margin: '16px 0' }} />

                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Value Date</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Original transaction row (read-only) */}
                            <TableRow>
                                <TableCell>
                                    {transaction.transaction_information?.length > 70
                                        ? transaction.transaction_information.slice(0, 70) + '...'
                                        : transaction.transaction_information}
                                </TableCell>
                                <TableCell>
                                    {new Date(transaction.value_date_time).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {originalAmount.toLocaleString('en-US')}
                                </TableCell>
                            </TableRow>

                            {/* Sub-transaction rows (read-only) */}
                            {subTransactions.map((sub, index) => (
                                <TableRow key={index}>
                                    <TableCell>{sub.description}</TableCell>
                                    <TableCell>
                                        {new Date(sub.value_date).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        {sub.amount.toLocaleString('en-US')}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Divider style={{ margin: '16px 0' }} />

                    <Typography variant="body1">
                        Original Amount: {originalAmount.toLocaleString('en-US')}
                    </Typography>
                    <Typography variant="body1">
                        Sub-Total: {totalSubAmount.toLocaleString('en-US')}
                    </Typography>
                    <Typography variant="body1" color={amountsMatch ? 'inherit' : 'error'}>
                        Remaining: {remainingAmount.toLocaleString('en-US')}
                    </Typography>

                    <Box marginTop={2} display="flex" justifyContent="space-between">
                        <Button onClick={handleBack} color="secondary">
                            Back
                        </Button>
                        <Button
                            onClick={handleConfirmClick}
                            variant="contained"
                            color="primary"
                            disabled={!amountsMatch}
                        >
                            Confirm
                        </Button>
                    </Box>
                </>
            )}
        </Drawer>
    );
};

export default TransactionBreakdownDrawer;
