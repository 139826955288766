// src/components/Login.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { login } from '../../services/api';
import '../../css/Auth.css'; // Import the CSS file
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../features/users/UserSlice';
import { RootState } from '../../reducers/reducers';
import ForgotPassword from './ForgotPassword';
import { Link } from 'react-router-dom';
import SEJELLogo from '../../assets/png_trns_logo_white 1.svg';
import GoogleIcon from '../../assets/GoogleIcon.png';
import axios from 'axios';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPrefilled, setIsPrefilled] = useState(false);
  const [error, setError] = useState<React.ReactNode | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    const inputs = document.querySelectorAll('.user-box input') as NodeListOf<HTMLInputElement>;
    const checkPrefilled = () => {
      const emailInput = document.getElementById('email') as HTMLInputElement
      const passwordInput = document.getElementById('password') as HTMLInputElement;

      // Check if the inputs are prefilled by their current value
      if (emailInput && passwordInput) {
        // alert(emailInput.value)
        if (emailInput.value || passwordInput.value) {
          setIsPrefilled(true);
        }

      }
    }


    inputs.forEach(input => {
      if (input.value) {
        input.classList.add('not-empty');
      }
      input.addEventListener('input', (event) => {
        if ((event.target as HTMLInputElement).value) {
          (event.target as HTMLInputElement).classList.add('not-empty');
        } else {
          (event.target as HTMLInputElement).classList.remove('not-empty');
        }
      });
    });
    checkPrefilled();
  }, []);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      console.log('Before calling login function: ' + "https://app.sejel.io/api");
      // const response = await login(email, password);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
      console.log('After calling login function');

      const userData = response.data.user;
      const role = response.data.role;
      localStorage.setItem('role', JSON.stringify(role));
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('token', response.data.token);
      navigate(from, { replace: true }); // Redirect to the previous route or default route
      dispatch(setUser(userData));
    } catch (error: any) {
      if (error.response && error.response.status === 444) {
        console.clear();
        console.log();
        setError(
          <div>
            <p>Please confirm your account on your email</p>
            <p>If you need any help, please contact <a href="#">support</a>.</p>
          </div>
        );
      } else {
        setError('An error occurred. Please try again.');
        console.error(error);
      }
    }
  };

  return (
    <div className='login-page'>
      <div className="flex justify-between align-items-center">
        <div className="flex-2-2 left-side">
          <div className="login-container">
            <div className="login-box">
              <form onSubmit={handleSubmit}>
                <h2>Sign into your account</h2>
                <div className="upper-pert">
                  <div className="user-box">
                    <label>Email</label>
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="user-box">
                    <label>Password</label>
                    <input
                      type="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="forget-password-container flex-container flex-start mt-3">
                    <Link className='forget-password' to="/forget-password">Forgot your password?</Link>
                  </div>
                </div>
                {/* <div className="accept-terms flex-container flex-start m-t-2">
                  <input type="checkbox" id="accept-terms" />
                  <label htmlFor="accept-terms" className='m-l-1'>I accept the <span className='term-color'>Terms and Conditions</span></label>
                </div> */}
                <div className="lower-part">
                  <button type="submit" className="login-button">
                    Sign in
                  </button>
                  {error && <span className="error transparent-border">{error}</span>}
                  <div className="google-button flex-container flex-center">
                    <div className="icon">
                      <img src={GoogleIcon} alt="google" className='image-w-3' />
                    </div>
                    <div className="text">Continue with Google</div>
                  </div>
                </div>
              </form>
              <div className="register-section">
                <p>New to Sejel? <Link to='/register'>Create an Account</Link></p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-side flex-2-2">
          <img src={SEJELLogo} alt="logo" className="logo" />
        </div>
      </div>
    </div >
  );
};

export default Login;
