import React, { useEffect, useState } from 'react'
import Users from './../../assets/Users.svg'
import WorkspaceInfo from './../../assets/WorkspaceInfo.svg'
import CreditCard from './../../assets/CreditCard.svg'
import Expand from './../../assets/Expand.svg'
import TTP from './../../assets/TTP.svg'
import Gray from './../../assets/Gray.svg'
import PinkLogo from './../../assets/PinkLogo.svg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Workspace } from '../../types/Workspace'
import { WriteStream } from 'node:fs'
import { useDispatch } from 'react-redux'
import { setSelectedWorkspace } from '../../features/WorkspaceSlice'

interface Props {
  pages: { [key: string]: string[] };
  pageProp: string;
  hidePopup: boolean;
  hidePanelContents: boolean;
  selectedWorkspace: Workspace;
}

const LandingMenu: React.FC<Props> = ({ pages, pageProp, hidePopup, hidePanelContents, selectedWorkspace }) => {
  const workspacesState = useSelector((state: RootState) => state.workspaceState);
  const dispatch = useDispatch();

  const accordionClick = (e: React.MouseEvent<HTMLElement>) => {
    const workspaceInfo = document.getElementById('workspace-info') as HTMLElement;
    if (workspaceInfo) {
      if (workspaceInfo.style.maxHeight) {
        workspaceInfo.style.maxHeight = "";
        setTimeout(() => {
          workspaceInfo.style.display = "none";
        }, 380);
      } else {
        workspaceInfo.style.display = "block";
        workspaceInfo.style.maxHeight = workspaceInfo.scrollHeight + "px";
      }
    }
  }

  const showWorkspaceContainer = () => {
    const workspaceContainer = document.getElementsByClassName('workspaces-container')[0] as HTMLElement;
    if (workspaceContainer) {
      workspaceContainer.classList.toggle('show');
    }
  }

  const handleChangeWorkspace = (e: React.MouseEvent<HTMLElement>) => {
    const workspaceId = e.currentTarget.getAttribute('data-id');
    workspacesState.workspaces.filter((workspace) => {
      if (workspace.id === parseInt(workspaceId as string)) {
        dispatch(setSelectedWorkspace(workspace));
      }
    })
  }

  useEffect(() => {
    console.log("LandingMenu: selectedWorkspace: ", selectedWorkspace);
  }, [selectedWorkspace]);

  return (
    <div id="menu-side" className='flex-2-4 p-l-1'>
      <div>
        <Link to="/">
          <img id='landing-menu-logo' className='logo' src={PinkLogo} alt="" />
        </Link>
      </div>
      {hidePanelContents ? <></> :
        <div className="menu-main-content flex-col flex flex-space-between">
          <div id='option-container' className="options">
            {
              hidePanelContents ? ''
                :
                <ul className="option-list">
                  {Object.entries(pages).map(([page, arr]) => {
                    return (
                      arr[0] === ''
                        ?
                        <li key={page} className={`option-list-item flex-container flex-start rounded-radius items-center p-l-1 m-t-1 default-cursor ${pageProp.toUpperCase() === page.toUpperCase() ? 'selected-option' : ''}`}>
                          <img src={arr[1]} alt="" />
                          <p className=' padding-half'>{page}</p>
                        </li>
                        :
                        <Link to={arr[0]} key={page}>
                          <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 ${pageProp.toUpperCase() === page.toUpperCase() ? 'selected-option' : ''}`}>
                            <img src={arr[1]} alt="" />
                            <p className=' padding-half'>{page}</p>2
                          </li>
                        </Link>
                    );
                  })}
                </ul>
            }
          </div>
          <ul id='workspace-ul' className="option-list current-workspace">
            {
              hidePopup ? '' :
                <li id='workspace-info' className="companies-container rounded-radius">
                  <ul>
                    <Link to={`/workspace-info/${selectedWorkspace.id != 0 ? selectedWorkspace.uniCode : ''}`}>
                      <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 ${pageProp.toUpperCase() == 'WORKSPACE' ? 'selected-option' : ''}`}>
                        <p className='upper-case padding-half'>Workspace Info</p>
                      </li>
                    </Link>
                    {
                      selectedWorkspace.id > 0 ?
                        <Link to={`/collaborator/${selectedWorkspace.uniCode}`}>
                          <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 ${pageProp.toUpperCase() == 'COLLABORATOR' ? 'selected-option' : ''}`}>
                            <p className='upper-case padding-half'>Collaborators</p>
                          </li>
                        </Link>
                        :
                        <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 default-cursor ${pageProp.toUpperCase() == 'COLLABORATOR' ? 'selected-option' : ''}`}>
                          <p className='upper-case padding-half'>Collaborators</p>
                        </li>
                    }
                    <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 default-cursor ${pageProp.toUpperCase() == 'PLAN AND BILLING' ? 'selected-option' : ''}`}>
                      <p className='upper-case padding-half'>Plan and Billing</p>
                    </li>
                    {
                      workspacesState.workspaces.length <= 1
                        ?
                        ''
                        :
                        <>
                          <li className={`pointer option-list-item flex-container flex-start rounded-radius p-l-1 ${pageProp.toUpperCase() == 'PLAN AND BILLING' ? 'selected-option' : ''}`}
                            onClick={showWorkspaceContainer}>
                            <p className='upper-case padding-half'>Switch Accounts </p>
                          </li>
                          <div className="workspaces-container ">
                            <ul>
                              {
                                workspacesState.workspaces.map((workspace) => {
                                  return (
                                    <li key={workspace.id}
                                      className={`option-list-item flex-container flex-start rounded-radius p-l-1 ${selectedWorkspace.id == workspace.id ? 'selected-option' : ''}`}
                                      onClick={handleChangeWorkspace}
                                      data-id={workspace.id}
                                    >
                                      <img src={workspace.image == '' ? Gray : process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + workspace.image} alt="logo" className='landing-menu-logo' />
                                      <p className='upper-case padding-half'>{workspace.name}</p>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </>
                    }
                  </ul>
                </li>
            }
            <li className={`option-list-item flex-container flex-start rounded-radius flex-align-center`} onClick={accordionClick}>
              {selectedWorkspace ?
                <img
                  src={
                    workspacesState.workspaces.length === 0 ? Gray
                      :
                      selectedWorkspace.id == 0 && selectedWorkspace.image != "" ? process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + selectedWorkspace.image
                        : selectedWorkspace.id == 0 && workspacesState.workspaces[0].image == "" ? Gray
                          : selectedWorkspace.id == 0 && workspacesState.workspaces[0].image != "" ? process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + workspacesState.workspaces[0].image
                            :
                            process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + selectedWorkspace.image}
                  alt="logo" className='landing-menu-logo'
                />
                :
                <img src={Gray} alt="logo" className='landing-menu-logo' />
              }

              {

                <p className="upper-case">{
                  selectedWorkspace.id == -1 ? 'Loading...'
                    :
                    workspacesState.workspaces.length === 0 ? 'NO WORKSPACE'
                      :
                      selectedWorkspace && selectedWorkspace.id == 0 ? workspacesState.workspaces[0].name + ' - WORKSPACE' + selectedWorkspace.id
                        :
                        selectedWorkspace && selectedWorkspace.name + ' - WORKSPACE'}
                </p>
                // :
                // <p className="upper-case">NO WORKSPACE</p>
              }
              <img src={Expand} alt="" style={{ height: '4rem' }} />
            </li>
          </ul>

        </div >
      }
    </div >
  )
}

export default LandingMenu