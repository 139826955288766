import { faAngleDown, faExpandArrowsAlt, faFingerprint, faList, faSync, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
} from '@mui/material';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { set } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Client } from '../../types/Client';
import { AccountBalance, ExpandMore, FilterList, OpenInFull, Sort } from '@mui/icons-material';
import Navbar from '../layouts/Navbar';
import Dropdown from '../shapes/Dropdown';
import DatePicker from '../shapes/DatePicker';
import { getCurrentDate, withSeparator } from '../../utilities/helper';
import '../../css/itinerary.css'
import { ItineraryBase } from '../../types/ItineraryBase';
import { click } from '@testing-library/user-event/dist/click';
import { faNote } from '@fortawesome/pro-thin-svg-icons';
import useWorkspaceAccess from '../../hooks/useWorkspaceAccess';
const token = localStorage.getItem('token');


const verticals = ['RCU', 'FIT', 'CRUISE', 'INTERNAT.', 'ADVISORY', 'R & T', 'ACI', 'GMU', 'DMMO', 'OTHER', 'EQUESTRIAN', 'SEMI - G', 'EXP', 'CONCIERGE']
const collectionStatusLabels: string[] = [
  'PARTIAL DUE',
  'PARTIAL',
  'SETTLED',
  'OUTSTANDING',
  'COLLECTED',
  'NOT COLLECTED',
  'CANCELLED',
  'SUBMITTED TO B.O',
  'NOT SUBMITTED',
  'DUE WITH RCU FI...',
  'WRITE-OFF',
  'SUBMITTED',
  'RESUBMITTED TO ...',
  'PAYTABS DUE',
  'COLLECTED REVIEW',
  'COLLECTED - AHM...',
  'ERROR',
  'VAT ERROR',
  'CREDIT NOTE',
  'UNKOWN',
  'CN',
  'LEADS',
  'DEPOSIT',
  'COLLECTED - REVIDT',
  'PENDING',
  'REVIEW',
  'CANCELLED']
const category = ["RCU", "RCU - NON.RCU"]


interface MemoizedTableRowProps {
  updateItineraryOnServer: (id: number, name: string, value: string) => void;
  index: number;
  itinerary: ItineraryBase;
  navigateToDetails: (e: any, name: any, id: any) => void;
  handleContextMenu: (e: React.MouseEvent<Element>, rowId: number) => void;
  handleOptionClick: (action: string, rowId: number) => void;
  menuVisibleRowId: number | null;
  menuVisible: boolean;
  menuPosition: { x: number; y: number };
  clients: Client[];
  handleShowClientList: (e: React.MouseEvent<HTMLElement>) => void;
  handleShowClientForm: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleClientSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleHideClientForm: () => void;
  handleAddNewClient: (e: React.FormEvent) => Promise<void>;
  handleShowDialogue: (e: React.MouseEvent<HTMLElement>) => void;
  displayTableCells: (title: string, itinerary: ItineraryBase) => any;
  updateDataFromDropdown: (rowId: number, cellName: string, newValue: string) => Promise<void>;
  updateItineraryClient: (itineraryId: number, clientId: number) => Promise<void>;
}

const ItineraryTableRow: React.FC<MemoizedTableRowProps> = ({
  updateItineraryOnServer,
  index,
  itinerary,
  navigateToDetails,
  handleContextMenu,
  handleOptionClick,
  menuVisibleRowId,
  menuVisible,
  menuPosition,
  clients,
  updateItineraryClient,
  handleShowClientList,
  handleShowClientForm,
  handleClientSearch,
  handleHideClientForm,
  handleAddNewClient,
  handleShowDialogue, // Ensure this is destructured
  displayTableCells,
  updateDataFromDropdown,

}) => {
  // console.log('rendered ' + index);
  const clientListRef = useRef<HTMLDivElement>(null);
  const [rowState, setRowState] = useState(itinerary);
  useEffect(() => {
    setRowState(itinerary);
  }, [itinerary]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    value = value.replace(/,/g, '');
    setRowState((prev) => ({ ...prev, [name]: value }));
    updateItineraryOnServer(rowState.id, name, value);
  };

  const handleChangeDataFromDatePicker = (id, name, value) => {
    setRowState((prev) => ({ ...prev, [name]: value }));
    updateItineraryOnServer(rowState.id, name, value);
  };

  const handleChangeDataFromDropDown = (id, name, value) => {
    setRowState((prev) => ({ ...prev, [name]: value }));
    updateItineraryOnServer(rowState.id, name, value);
  };

  const handleShowSelectedClient = (e: React.MouseEvent<HTMLLIElement>) => {
    const target = e.currentTarget as HTMLLIElement;
    const clientList = target.closest('.client-list') as HTMLElement;
    clientList.style.display = 'none';
    const clientId = parseInt(e.currentTarget.getAttribute('data-id') || '0', 10);
    const clientName = e.currentTarget.textContent?.split('-')[0].trim() || '';

    setRowState((prev) => ({ ...prev, client_id: clientId, client_name: clientName }));

    updateItineraryClient(rowState.id, clientId); // Call parent function to update backend
  };

  return (
    <TableRow key={index}
      className="table-row even:bg-gray-50 hover:bg-blue-50 transition-colors cursor-pointer"
      data-id={itinerary.id}>
      <TableCell padding="checkbox" style={{ width: '25px' }}>
        <Checkbox />
      </TableCell>
      <TableCell padding="none">
        <IconButton>
          <ExpandMore />
        </IconButton>
      </TableCell>
      <TableCell padding="none">
        <IconButton>
          <OpenInFull
            className='expand-icon pointer'
            onClick={(e) => navigateToDetails(e, rowState.ref_unique, rowState.id)}
            onContextMenu={(e) => handleContextMenu(e, rowState.id)} />
          {menuVisible && menuVisibleRowId === rowState.id && (
            <ul className='context-menu' style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, }}>
              <li className="border-b border-gray-200 hover:bg-gray-100  text-left" style={{ padding: '8px 12px', cursor: 'pointer' }}
                onClick={() => handleOptionClick('Delete', rowState.id)}
              >Delete</li>
              <li className="border-b border-gray-200 hover:bg-gray-100  text-left" style={{ padding: '8px 12px', cursor: 'pointer' }}
                onClick={() => handleOptionClick('Delete', rowState.id)}
              >Add Adjustment</li>
            </ul>
          )}
        </IconButton>
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }} className='description-cell' data-name="description">
        <input
          type="text"
          value={rowState.description}
          name='description'
          data-name='description'
          onChange={handleInputChange}
          // maxLength={maxChars}
          className="border-none outline-none bg-transparent w-[95%] ml-1 border-r-1"
        />
        <div className="has-dialogue w-fit" onClick={handleShowDialogue}>
          <FontAwesomeIcon icon={faNote} />
          <div className='dialogue w-[30rem]'>
            <p className='break-words whitespace-pre-wrap'>
              {rowState.description}
            </p>
          </div>
          {/* {
            rowState.description.length > maxChars
              ?
              : ''
          } */}
        </div>
      </TableCell>
      <TableCell className='custom-cell' style={{ borderRight: '0.5px solid #eeefff' }} data-name="customer">
        <div className="info-section client-section position-relative w-[15rem] h-[21px]">
          <p className='w-100' onClick={handleShowClientList}>{rowState.client_name != '' ? rowState.client_name : 'SELECT FROM CLIENTS'}</p>
          <div ref={clientListRef} className="client-list rounded-radius h-[100vh] !overflow-hidden fixed right-0">
            <div className='add-new-client' onClick={handleShowClientForm}>+ ADD A NEW CLIENT</div>
            <input type="text" name="" id="search-client" placeholder='Search...' onChange={handleClientSearch} />
            <ul className='overflow-y-scroll h-[90%]'>
              {clients &&
                clients.map(client => (
                  <li key={client.id} data-id={client.id} onClick={handleShowSelectedClient} className={`${rowState.client_id === client.id ? 'bg-gray-200' : ''} handleShowSelectedClient`}>{client.name}{client.email !== 'from@DatabaseSeeder.com' ? ' - ' + client.email : ''}</li>
                ))
              }
            </ul>
          </div>
          <div className="client-form-popup client-list rounded-radius">
            <span className='client-form-close' onClick={handleHideClientForm}>X</span>
            <div className='m-t-3'>
              <input type="text" placeholder='Full Name' />
              <br />
              <input type="email" placeholder='Email' />
              <br />
              <input type="button" className='clickable create-client-button' value="CREATE CLIENT" onClick={handleAddNewClient} />
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }} data-name="ref" className='has-dialogue' onClick={handleShowDialogue}>
        <FontAwesomeIcon icon={faFingerprint} className='w-100 align-center' />
        <div className="dialogue">
          {rowState.ref_unique}
        </div>
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }}>
        <DatePicker
          initialDate={displayTableCells('invoice_date', itinerary)}
          defaultSelectedDate={getCurrentDate()}
          onDateChange={(formattedDate) => updateDataFromDropdown(rowState.id, "invoice_date", formattedDate)}
        />
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }}>
        <DatePicker
          initialDate={displayTableCells('value_date', itinerary)}
          defaultSelectedDate={getCurrentDate()}
          onDateChange={(formattedDate) => handleChangeDataFromDatePicker(rowState.id, "value_date", formattedDate)}
        />
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }} data-name="verticals">
        <Dropdown
          items={verticals}
          selectedItem={displayTableCells('verticals', itinerary)}
          defaultSelectedItem='-'
          onChange={(selectedItem) => handleChangeDataFromDropDown(rowState.id, "verticals", selectedItem)}
          otherClasses='w-[83px] h-[21px]'
          fieldName='category'
        />
      </TableCell>
      {/* <TableCell style={{ borderRight: '0.5px solid #eeefff' }}>
        <Dropdown
          items={category}
          selectedItem={displayTableCells('category', itinerary)}
          defaultSelectedItem='-'
          onChange={(selectedItem) => handleChangeDataFromDropDown(rowState.id, "category", selectedItem)}
          otherClasses='w-[83px] h-[21px]'
          fieldName='category'
        />
      </TableCell> */}
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }}>
        <input
          type="text"
          value={withSeparator(rowState.tta)}
          name='tta'
          data-name='tta'
          onChange={handleInputChange}
          className="border-none outline-none bg-transparent w-[90%] ml-1 text-right"

        />
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }}>
        <input
          type="text"
          value={withSeparator(rowState.vat)}
          name='vat'
          data-name='vat'
          onChange={handleInputChange}
          className="border-none outline-none bg-transparent w-[90%] ml-1 text-right"

        />
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }}>
        <input
          type="text"
          value={withSeparator(rowState.total)}
          name='total'
          data-name='total'
          onChange={handleInputChange}
          className="border-none outline-none bg-transparent w-[90%] ml-1 text-right"

        />
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }}>
        <input
          type="text"
          value={withSeparator(Number(rowState.incoming) + Number(rowState.transactions_total))}
          name='incoming'
          data-name='incoming'
          onChange={handleInputChange}
          className="border-none outline-none bg-transparent w-[90%] ml-1 text-right"

        />
      </TableCell>
      <TableCell style={{ borderRight: '0.5px solid #eeefff' }}>
        <input
          type="text"
          value={withSeparator(Number(rowState.total) - Number(rowState.incoming) + Number(rowState.transactions_total))}
          name='receivable'
          data-name='receivable'
          onChange={handleInputChange}
          className="border-none outline-none bg-transparent w-[90%] ml-1 text-right"

        />
      </TableCell>
      <TableCell>
        <Dropdown
          items={collectionStatusLabels}
          selectedItem={displayTableCells('collection_status', itinerary)}
          defaultSelectedItem='-'
          onChange={(selectedItem) => handleChangeDataFromDropDown(rowState.id, "collection_status", selectedItem)}
          otherClasses='w-[83px] h-[21px]'
          fieldName='category'
          conditionClasses={(item) => {
            if (item === 'SETTLED' || item === 'COLLECTED') return 'bg-green-500 text-white';
            if (item === 'PARTIAL DUE') return 'bg-orange-500 text-white';
            if (item === 'PARTIAL') return 'bg-orange-500 text-white';
            if (item === 'OUTSTANDING') return 'bg-red-500 text-white';
            return 'bg-white';
          }}
        />
      </TableCell>
    </TableRow>
  )
}

const MemoizedTableRow = React.memo(ItineraryTableRow);
interface Itinerary {
  id: number;
  name: string;
  description: string;
  uniItineraryCode: string;
  client_id: number;
  client_name: string;
  ref_unique: string;
  invoice_date: string;
  y_date: string;
  value_date: string;
  verticals: string;
  tta: number;
  vat: number;
  incoming: number;
  total: number;
  receivable: number;
  transactions_total: number;
  collection_status: string;
  category: string;
}

const ItineraryList = () => {
  const descRef = useRef<HTMLTableHeaderCellElement>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [loading, setLoading] = useState(false);
  const { collectionStatus } = useParams();
  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const [filter, setFilter] = useState('all');
  const [clients, setClients] = useState<Client[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [client, setClient] = useState('');

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuVisibleRowId, setMenuVisibleRowId] = useState<number | null>(null); // Track the row ID for the context menu
  const [expandedItinerary, setExpandedItinerary] = useState<Itinerary>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (collectionStatus) {
      fetchInitialData(currentPage, collectionStatus);
    }

    const tableContainer = document.querySelector('.table-container') as HTMLElement;
    const handleScroll = () => {
      if (tableContainer) {
        const { scrollTop, scrollHeight, clientHeight } = tableContainer;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          // User has scrolled to the bottom, load more data
          handlePageChange(currentPage + 1);
        }
      }
    };

    if (tableContainer) tableContainer.addEventListener('scroll', handleScroll);

    return () => {
      if (tableContainer) tableContainer.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, collectionStatus]);

  const navigate = useNavigate();

  // const averageCharWidth = 5;
  // const [inputValue, setInputValue] = useState<string>('');
  // const [maxChars, setMaxChars] = useState<number>(0);

  // useEffect(() => {
  //   if (descRef.current) {
  //     const descWidth = descRef.current.offsetWidth;
  //     alert(descWidth)
  //     const calculatedMaxChars = Math.floor(descWidth / averageCharWidth);
  //     alert(calculatedMaxChars)
  //     setMaxChars(calculatedMaxChars);
  //     const itineraryTable = document.getElementById('itinerary-table') as HTMLTableElement;
  //     const tbody = itineraryTable.querySelector('tbody')
  //     const firstRow = tbody?.querySelector('tr')
  //     if (firstRow) {
  //       // alert(413)
  //       firstRow.classList.add('test');
  //     }
  //   }
  // }, [descRef.current]);

  // Run the effect when the header is created
  // useEffect(() => {
  //   if (descRef.current) {
  //     console.log('Header is created!');
  //     // Calculate the maximum number of characters that can fit in the input
  //     if (descRef.current) {
  //       const descWidth = descRef.current.offsetWidth;
  //       const calculatedMaxChars = Math.floor(descWidth / averageCharWidth);
  //       setMaxChars(calculatedMaxChars);
  //       alert(descWidth + "  " + averageCharWidth + "  " + calculatedMaxChars)
  //     }
  //   }
  // }, [descRef.current])


  const fetchInitialData = async (page: number, collectionStatus: string) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/get-itineraries?page=${page}&collection_status=${collectionStatus}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (page === 1) {
          setItineraries(data.itineraries.data);
        } else {
          setItineraries((prev) => [...prev, ...data.itineraries.data]);
        }
        setClients(data.clients);
        setTotalPages(data.itineraries.total_pages);
      } else {
        console.error('Error fetching clients');
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setIsLoading(false);
    }
  };



  const navigateToDetails = (e, name, id) => {
    e.preventDefault();
    navigate(`/ttp-itinerary/R6AmEkD4H/${name}`);
  }


  const [editingRows, setEditingRows] = useState({});

  const handleInputChange = useCallback(
    (id, name, value, e) => {
      setEditingRows((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [name]: value,
        },
      }));

      // Optional: Immediate UI update in the itineraries state
      setItineraries((prev) =>
        prev.map((itinerary) =>
          itinerary.id === id
            ? {
              ...itinerary,
              [name]: value,
            }
            : itinerary
        )
      );

      debouncedUpdateItineraryOnServer(id, name, value)
    },
    [setEditingRows, setItineraries]
  );


  const debouncedUpdateItineraryOnServer = debounce((id, name, value) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-itinerary`;

    axios.post(url, { id, name, value }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).catch((error) => {
      console.error('Error updating itinerary:', error);
    });
  }, 500);

  const updateItineraryOnServer = (id: number, name: string, value: string) => {
    debouncedUpdateItineraryOnServer(id, name, value);
  };

  const handleShowClientList = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget as HTMLElement;
    const clientList = target.nextElementSibling as HTMLElement;
    if (clientList.style.display == "") {
      const allClientList = document.querySelectorAll('.client-list') as NodeListOf<HTMLElement>;
      for (let i = 0; i < allClientList.length; i++) {
        const cl = allClientList[i];
        if (cl !== clientList) {
          cl.style.display = '';
        }
      }
      setTimeout(() => {
        clientList.style.display = 'block';
      }, 0);
    } else {
      clientList.style.display = '';
    }
  }

  const handleShowClientForm = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const clientList = target.closest('.client-list') as HTMLElement;
    const clientFormPopup = clientList.nextSibling as HTMLElement;

    if (clientFormPopup.style.display == "") {
      setTimeout(() => {
        clientFormPopup.style.display = 'block';
        clientList.style.display = '';
      }, 0);
    } else {
      clientFormPopup.style.display = '';
      clientList.style.display = 'block';
    }
  };


  const handleClientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const search = e.target.value;
    const clientListUl = document.querySelector('.client-list ul') as HTMLElement;
    const lis = clientListUl.getElementsByTagName('li');
    for (let i = 0; i < lis.length; i++) {
      const li = lis[i];
      if (li.textContent!.toLowerCase().includes(search.toLowerCase())) {
        setTimeout(() => {
          li.style.display = 'block';
        }, 0);
      } else {
        li.style.display = 'none';
      }
    }
  }

  const handleHideClientForm = () => {
    const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
    const clientList = document.querySelector('.client-list') as HTMLElement;
    clientFormPopup.style.display = '';
    setTimeout(() => {
      clientList.style.display = 'block';
    }, 0);
  }

  const handleShowSelectedClient = (e: MouseEvent | React.MouseEvent<HTMLLIElement>) => {
    const target = e.currentTarget as HTMLLIElement;
    const clientSection = target.closest('.client-section') as HTMLElement;
    const clientElement = clientSection.querySelector('p')
    const clientList = target.closest('.client-list') as HTMLElement;
    const itineraryId = target.closest('tr')!.getAttribute('data-id');
    const clientId = target.getAttribute('data-id')!;

    clientElement!.innerHTML = target.innerHTML.split('-')[0].trim();
    clientElement!.setAttribute('data-id', target.getAttribute('data-id')!);
    setClient(target.innerHTML.split('-')[0].trim());
    clientList.style.display = 'none';
    if (itineraryId) {
      updateItineraryClient(parseInt(itineraryId), parseInt(clientId));
    }
  }

  const updateItineraryClient = async (itineraryId: number, clientId: number) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-itinerary-client`;
    try {
      const response = await axios.post(
        url,
        { itineraryId, clientId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const updatedItinerary = response.data.itinerary;
      // setItineraries((prev) =>
      //   prev.map((itinerary) =>
      //     itinerary.id === itineraryId
      //       ? { ...itinerary, client_id: clientId, client_name: updatedItinerary.client_name }
      //       : itinerary
      //   )
      // );
    } catch (error) {
      console.error('Error updating client', error);
    }
  };


  const handleAddNewClient = async (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;;
    // const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
    const clientFormPopup = target.closest('.client-form-popup') as HTMLElement;
    const targetTd = clientFormPopup.closest('.custom-cell') as HTMLElement;
    const clientList = targetTd.querySelector('.client-list') as HTMLElement;
    const name = (clientFormPopup.querySelector('.client-form-popup input[type="text"]') as HTMLInputElement).value;
    const email = (clientFormPopup.querySelector('.client-form-popup input[type="email"]') as HTMLInputElement).value;
    // !! workspace_id is hardcoded since this enterprise is for TTP only
    const data = { name: name, email: email, workspace_id: 1, customerCode: '123' };
    const clientFormSubmit = clientFormPopup.querySelector('.create-client-button') as HTMLButtonElement;
    clientFormSubmit.classList.add('inactive');
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/create-client`;
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const clientListUl = targetTd.querySelector('.client-list ul') as HTMLElement;
      const li = document.createElement('li');
      li.addEventListener('click', handleShowSelectedClient);
      li.textContent = name + " - " + email;
      li.setAttribute('data-id', response.data.clientId)
      clientListUl.appendChild(li);
      setShowAlert(true);
      setAlertBody('Client has been added successfully!');
      setAlertStatus('success');
    } catch (error) {
      console.error('Error creating client', error);
      setShowAlert(true);
      setAlertBody('Client has not been added!');
      setAlertStatus('error');
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    clientFormPopup.style.display = '';
    setTimeout(() => {
      clientList.style.display = 'block';
    }, 0);
  }

  const createItinerary = async () => {
    const table = document.querySelector('table') as HTMLTableElement;
    const tbody = table.querySelector('tbody') as HTMLTableSectionElement;
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/create-itinerary`;

    const response = await axios.post(url, {
      name: 'New Itinerary',
      description: 'New Description',
      client_id: 1,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    setItineraries([response.data.itinerary, ...(itineraries || [])]);
  }

  const updateDataFromDropdown = useCallback(async (rowId: number, cellName: string, newValue: string) => {
    // if (itineraries) {
    //   setItineraries(itineraries.map((itinerary) => {
    //     if (itinerary.id === rowId) {
    //       return {
    //         ...itinerary,
    //         [cellName]: newValue,
    //       }
    //     }
    //     return itinerary;
    //   })
    //   );
    // }
    // send request to update the itinerary
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-itinerary`;
    const response = await axios.post(url, {
      id: rowId,
      name: cellName,
      value: newValue,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  }, []);

  const displayTableCells = useCallback((title: string, itinerary: Itinerary) => {
    let result: any = null;
    if (title === 'category') {
      result = itinerary.category;
    } else if (title === 'collection_status') {
      result = itinerary.collection_status;
    } else if (title === 'verticals') {
      result = itinerary.verticals;
    } else if (title === 'invoice_date') {
      result = itinerary.invoice_date || '';
    } else if (title === 'y_date') {
      result = itinerary.y_date !== null ? itinerary.y_date : null;
    } else if (title === 'value_date') {
      result = itinerary.value_date !== null ? itinerary.value_date : null;
    }
    return result;
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    const allClientList = document.querySelectorAll('.client-list') as NodeListOf<HTMLElement>;
    const filterPopup = document.querySelector('.filter-popup') as HTMLElement;
    const target = e.target as HTMLElement;

    allClientList.forEach(element => {
      if (!element.contains(target)) {
        const style = window.getComputedStyle(element);
        if (style.display === 'block') {
          element.style.display = 'none';
        }
      }
    });
    if (!filterPopup.contains(target)) {
      filterPopup.classList.remove('show')
    }

    hideDialogues();
  };


  const handleContextMenu = (e: React.MouseEvent, rowId: number) => {
    e.preventDefault(); // Prevent the default browser context menu
    setMenuVisible(true);
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setMenuVisibleRowId(rowId); // Track which row is active
  };

  const handleOptionClick = (action: string, rowId: number) => {
    if (action.toLowerCase() === 'delete') {
      // const confirmDelete = window.confirm('Are you sure you want to delete this itinerary?');
      // if (confirmDelete) {
      // }
      deleteItinerary(rowId);
    } else if (action.toLowerCase() === 'expand') {

      let selected: any;
      if (itineraries) {
        selected = itineraries.find((itinerary) => itinerary.id === rowId);
      }
      if (selected) {
        setExpandedItinerary(selected)
      }
      const itineraryExpand = document.getElementById('itinerary-expand') as HTMLDivElement;
      if (itineraryExpand.classList.contains('show')) {
        itineraryExpand.classList.remove('show')
      } else {
        itineraryExpand.classList.add('show')
      }
    }
    setMenuVisible(false); // Hide the menu after the action
    setMenuVisibleRowId(null); // Reset the active row
  };

  const deleteItinerary = async (rowId: number) => {
    try {
      setIsLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/delete-itinerary/${rowId}`;
      const response = await axios.post(
        url,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (itineraries) {
        const updatedItineraries = itineraries.filter((itinerary) => itinerary.id !== rowId);
        setItineraries(updatedItineraries); // This should trigger a re-render
      }
    } catch (error) {
      console.error('Error deleting itinerary detail:', error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleShowDialogue = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget;
    const dialogue = target.getElementsByClassName('dialogue')[0] as HTMLElement;
    const computedStyle = window.getComputedStyle(dialogue);
    setTimeout(() => {
      if (computedStyle.display === 'none') {
        setTimeout(() => {
          dialogue.style.display = 'block';
        }, 0);
      } else {
        dialogue.style.display = 'none';
      }
    }, 100);
    const refUnique = dialogue.textContent;
    // add refUique to clipboard
    if (refUnique) navigator.clipboard.writeText(refUnique);
  }

  const hideDialogues = () => {
    const dialogues = document.getElementsByClassName('dialogue') as HTMLCollectionOf<HTMLElement>;
    if (dialogues.length) {
      for (let i = 0; i < dialogues.length; i++) {
        const dialogue = dialogues[i];
        if (dialogue.style.display === 'block') {
          dialogue.style.display = 'none';
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   const tableContainer = document.querySelector('.table-container') as HTMLElement;

  //   const handleScroll = () => {
  //     if (tableContainer) {
  //       const { scrollTop, scrollHeight, clientHeight } = tableContainer;
  //       if (scrollTop + clientHeight >= scrollHeight - 10) {
  //         // User has scrolled to the bottom, load more data
  //         handlePageChange(currentPage + 1);
  //       }
  //     }
  //   };

  //   if (tableContainer) tableContainer.addEventListener('scroll', handleScroll);

  //   return () => {
  //     if (tableContainer) tableContainer.removeEventListener('scroll', handleScroll);
  //   };
  // }, [currentPage]);

  const handlePopupVisibility = () => {
    const popup = document.querySelector('.filter-popup') as HTMLElement;
    setTimeout(() => {
      if (popup) popup.classList.toggle('show');
    }, 10);
  }

  const handleFilter = (e: React.MouseEvent, title: string) => {
    // const target = e.currentTarget as HTMLElement;
    // const filter = target.getAttribute('data-filter') || '';
    // setFilter(filter);
    navigate(`/ttp-sales/R6AmEkD4H/${title}`);
  }

  useEffect(() => {
    try {
      if (filter === 'all' && currentPage !== 1) {
        setCurrentPage(1);
        fetchInitialData(1, 'all')
      } else {
        if (itineraries.length > 0) {
          const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/filter-itineraries/${filter}`;
          axios.get(url,
            { headers: { Authorization: `Bearer ${token}` } }
          ).then(response => {
            console.log(response);
            setItineraries(response.data.itineraries);
          }).finally(() => {
            const filterPopup = document.querySelector('.filter-popup') as HTMLElement;
            filterPopup.classList.remove('show');
          })
        }
      }

    } catch (error) {
      console.error('Error filtering itineraries:', error);
    }
  }, [filter]);

  // Function to trigger the sync
  const handleSync = async () => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      // Send a POST request to trigger the sync
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sync-workspace/1`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      setItineraries(response.data.itineraries.data)
      console.clear();
      console.log(response);
      // Display success message
    } catch (error) {
      // Handle errors and display an error message
      console.error('Error syncing:', error);
    } finally {
      setIsLoading(false);
    }
  };
  // ✅ Call the hook at the top level of the component, NOT inside useEffect
  const hasAccess = useWorkspaceAccess('R6AmEkD4H');
  if (!hasAccess) {
    return <Navigate to="/not-authorized" />;
  }
  return (
    <>
      <div className='flex align-items-center pl-4'>
        {/* <IconButton
          className="ml-4"
          onClick={link}
          color="primary" // Optional: Adjust color to match your theme
          size="large"
          aria-label="Connect Bank Account" // Accessibility label
        > */}
        <IconButton size="small">
          <AccountBalance />
        </IconButton>
        <IconButton size="small">
          <Sort />
        </IconButton>
        <IconButton size="small">
          <FilterList />
        </IconButton>
        <IconButton onClick={handlePopupVisibility} size="small" className='w-[2.5rem]'>
          <FontAwesomeIcon icon={faList} />
        </IconButton>
        <IconButton onClick={handleSync} size="small" className='w-[2.5rem]'>
          <FontAwesomeIcon icon={faSync} className='ml-2' />
        </IconButton>
        {isLoading ? <p className="bold-text inline-block" style={
          {
            'color': '#94ddf3',
            'display': 'inline-block',
            'marginLeft': '1rem',
            'fontSize': '2rem',
          }}>
          Loading...
        </p> : ""}
        {/* </IconButton> */}
      </div>
      <TableContainer component={Paper} style={{ height: '100vh', marginTop: '0px', overflowY: 'auto' }} className="table-container">
        <Table stickyHeader id='itinerary-table'>
          <TableHead>
            <TableRow className="table-header">
              <TableCell className='w-[25px]' padding="checkbox" style={{ width: '25px' }} />
              <TableCell className='w-[25px]' padding="none" />
              <TableCell className='w-[25px]' padding="none" />
              <TableCell className="" style={{ borderRight: '0.5px solid #eeefff' }}>description</TableCell>
              <TableCell className="align-center w-[16rem]" style={{ borderRight: '0.5px solid #eeefff' }}>customer</TableCell>
              <TableCell className="align-center w-[3rem]" style={{ borderRight: '0.5px solid #eeefff' }}>RU</TableCell>
              <TableCell className="align-center w-[5rem]" style={{ borderRight: '0.5px solid #eeefff' }}>INVOICE DAT</TableCell>
              <TableCell className="align-center w-[5rem]" style={{ borderRight: '0.5px solid #eeefff' }}>VALUE DATE</TableCell>
              <TableCell className="align-center w-[7rem]" style={{ borderRight: '0.5px solid #eeefff' }}>VERTICALS</TableCell>
              {/* <TableCell className="align-center w-[7rem]" style={{ borderRight: '0.5px solid #eeefff' }}>CATEGORY</TableCell> */}
              <TableCell className="w-[7rem]" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}>TTA</TableCell>
              <TableCell className="w-[7rem]" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}>VAT</TableCell>
              <TableCell className="w-[7rem]" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}>TOTAL</TableCell>
              <TableCell className="w-[7rem]" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}>INCOMING</TableCell>
              <TableCell className="w-[7rem]" style={{ borderRight: '0.5px solid #eeefff', textAlign: 'right' }}>RECEIVABLE</TableCell>
              <TableCell className="align-center w-[6rem] max-w-[6rem]" style={{ borderRight: '0.5px solid #eeefff' }}>COL. STATUS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients && itineraries && itineraries.map((itinerary, index) => (
              <MemoizedTableRow
                key={index}
                updateItineraryOnServer={updateItineraryOnServer}
                index={index}
                itinerary={itinerary}
                navigateToDetails={navigateToDetails}
                handleContextMenu={handleContextMenu}
                handleOptionClick={handleOptionClick}
                menuVisibleRowId={menuVisibleRowId}
                menuVisible={menuVisible}
                menuPosition={menuPosition}
                clients={clients}
                updateItineraryClient={updateItineraryClient}
                handleShowClientList={handleShowClientList}
                handleShowClientForm={handleShowClientForm}
                handleClientSearch={handleClientSearch}
                handleHideClientForm={handleHideClientForm}
                handleAddNewClient={handleAddNewClient}
                handleShowDialogue={handleShowDialogue} // Ensure this is included
                displayTableCells={displayTableCells}
                updateDataFromDropdown={updateDataFromDropdown}
              // maxChars={maxChars}
              />
            ))}
          </TableBody>
        </Table>
        {/* <div className='inline-block'>
          <button
            className={`navigate-button clickable pointer disabled ${currentPage === 1 ? 'disabled' : ''}`}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
          <button
            className='navigate-button clickable pointer'
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div> */}
      </TableContainer>
      <div className='create-invoice create-button clickable pointer' onClick={createItinerary}>Create Itinerary</div>
      <div className="filter-popup ">
        <div className="filter clickable pointer hover:bg-[var(--light-gray)]" onClick={(e) => handleFilter(e, 'all')}>ALL</div>
        <div className="filter clickable pointer hover:bg-[var(--light-gray)]" onClick={(e) => handleFilter(e, 'outstanding_and_partial')}>Outstanding / Partial</div>
        {
          collectionStatusLabels.map((label, index) => (
            <div className="filter clickable pointer hover:bg-[var(--light-gray)]" onClick={(e) => handleFilter(e, label)}>{label}
              <span className="absolute left-0 top-[24px] hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                Category
              </span>
            </div>
          ))
        }
        {/* <div className="filter clickable pointer hover:bg-[var(--light-gray)]" onClick={(e) => handleFilter(e, 'partial')}>Partial</div> */}
      </div>
    </>
  );
}
export default React.memo(ItineraryList)